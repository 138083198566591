import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../../assets/svg/chevron-left.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {   Form, Input, Spin } from "antd";
import PasswordChecklist from "react-password-checklist";
import circle from "../../../../assets/img/Image 258.png"; 
import toast from "react-hot-toast";
import AuthTaSideImage from "../../../../components/auth-ta/AuthTaSideImage";
import { reset } from "../../../../features/wsp_registration/wspRegSlice";
import { save, saveReg } from "../../../../features/save/saveSlice";

function PasswordTaField() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { 
    basicDetails,
    contactDetails, 
    wspLoading,
  } = useSelector((state) => state.wspReg);

  const {saving} =  useSelector((state)=>state.save)

  const [password, setPassword] = useState("");
 
   

  const onFinish = async (data) => {
    const isPasswordValid =
      password.length >= 8 && // Minimum length
      /\d/.test(password) && // At least one digit
      /[A-Z]/.test(password) && // At least one uppercase letter
      /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/.test(password); // At least one special character

    if (!isPasswordValid) {
      toast.error(
        "Password does not meet the required conditions or does not match"
      );
      return;
    } else { 
      const res = await dispatch(save({
          url:'/usr/create_account.action',
          ...basicDetails,
          ...contactDetails, 
          accTypeId: 2,
          usrEncryptedPassword:password
      }));
      if(res?.payload?.success){
        await toast.success(res?.payload?.messages?.message)
        await dispatch(reset());
        await navigate('/login')
      }else{
        toast.error(res?.payload?.messages?.message)
      } 
    }
  };

  useEffect(() => {}, [ 
    basicDetails,
    contactDetails, 
  ]);

  return (
    <>
      <div className="lg:grid lg:grid-cols-6">
        <div className="bg-[#226cbf] w-full h-[100vh] col-span-2 relative hidden lg:block">
          <AuthTaSideImage hideStepper={false} hideText={false} current={4} />
        </div>
        <div className="lg:col-span-4">
          <div
            className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[48px] items-center justify-center flex"
            onClick={() => navigate("/signup-ta/contact-details")}
          >
            <img src={leftArrow} alt="backarrow" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={logo} alt="logo" style={{ marginTop: "10px" }}  onClick={()=>navigate('/')}/>

            <div class="text-center text-blackest text-[28px] font-bold font-dmSans leading-[44px] mt-[1.56rem]">
              Lastly, let’s set up your password
            </div>
            <div class="w-[31.0625rem] password-p">
            Create a strong password to secure your account.
            </div>
            <div className="flex justify-center mt-[3.81rem]">
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                className="lg:w-[700px] w-auto max-w-[100%]"
                form={form}
                initialValues={{
                  requiredMarkValue: false,
                }}
                requiredMark={false}
              >
                <div className="flex flex-col mb-[-23px]">
                  <Form.Item
                    name="usrEncryptedPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please add password",
                      },
                    ]}
                  >
                    <Input.Password
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Create password"
                      className="reg-input"
                    />
                  </Form.Item>

                    <div className="password-contain !mt-[-.75rem]">Password must contain:</div>
                  <PasswordChecklist
                    className="font-dmSans text-[#147CBC] font-[400] text-[0.875rem] flex flex-col w-full justify-start items-start"
                    rules={["minLength", "specialChar", "number", "capital"]}
                    minLength={8}
                    value={password}
                    messages={{
                      minLength: "A minimum of 8 characters",
                      specialChar: "At least one symbol (~!@$&)",
                      number: "At least one number (0-9)",
                      capital: "At least one upper case letter (A-Z)",
                    }}
                    iconComponents={{
                      ValidIcon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 text-darkBlue"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      ),
                      InvalidIcon: (
                        <img
                          src={circle}
                          className="h-[22px] mr-2 inline-block align-text-bottom"
                        />
                      ),
                    }}
                    validColor="#147CBC"
                    iconSize={5}
                  />
                </div>

                <div className="text-center mt-[103px]">
                  <button
                    disabled={saving}
                    className="w-[172px] h-[52px] px-5 py-2.5 bg-darkBlue rounded-[52px] justify-center items-center gap-2 inline-flex
                text-white text-[18px] font-medium font-dmSans leading-normal"
                    type="submit"
                  >
                    {saving ? <Spin /> : "Continue"}
                  </button>
                </div>
              </Form>
            </div>
            <div className="absolute bottom-1 mt-10 mb-[30px] text-center text-darkBlue text-[14px] font-normal font-['Inter'] leading-snug">
              Privacy Policy • Terms and Conditions
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PasswordTaField;
