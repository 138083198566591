import BacomeAPartner from './BacomeAPartner';
import FAQ from './FAQ';
import Footer from './Footer';
import HomeHeader from './HomeHeader';
import HowToRequest from './HowToRequest';
import KeyFeatures from './KeyFeatures';
import Landing from './Landing';
import NeedWater from './NeedWater';
import TrustedPartners from './TrustedPartners';
import WhatPeopleSay from './WhatPeopleSay';
import WhyFundMyWater from './WhyFundMyWater';
import HomeMobile from './home_mobile/HomeMobile';

export default function Home() {
	if (window.innerWidth <= 1024) {
		return (
			<div className='w-full h-full bg-white'>
				<HomeMobile />
			</div>
		);
	}

	return (
		<>
			<div className='w-full h-full bg-white'>
				<HomeHeader />
				<div className='w-full h-auto'>
					<Landing />
				</div>
				<HowToRequest />
				<WhyFundMyWater />
				<KeyFeatures />
				<NeedWater />
				<BacomeAPartner />
				<TrustedPartners />
				<WhatPeopleSay />
				<FAQ />
				<Footer />
			</div>
		</>
	);
}
