import React, { useRef, useState } from 'react'
import PageHeader from '../../../components/PageHeader'
import { AntDesignOutlined, EditOutlined } from '@ant-design/icons';
import { Divider, Avatar, Form, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentUser, updateUser, fetchAllUser } from '../../../features/auth/authSlice';

import { formatImgPath } from '../../../utils';
import { uploadFile } from '../../../features/obj/objSlice';

const EditAccount = ({ setIsEdit }) => {
    const { accLocation, accTradeName, usrId, accIcon, accId, accRegNo, accCounty, accEmail, accPhoneNumber, usrMobileNumber, usrEmail, usrLastName, usrFirstName } = useSelector((state) => state.auth.user);
    const [imageSrc, setImageSrc] = useState(formatImgPath(accIcon == null ? "./myimages/OTP_IMAGES/FUND_MY_WATER/avatar.png" : accIcon));
    const [accProfile, setAccProfile] = useState(null);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const userData = { accId, usrId };


    const handleImageChange = async (e) => {

        let file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        const res = await dispatch(uploadFile(formData))
        setImageSrc(formatImgPath(res.payload));
        setAccProfile(res.payload);
    };

    const onFinish = async (data) => {
        const allData = { accId, usrId, accIcon: accProfile, ...data };
        dispatch(updateUser(allData));
        dispatch(fetchCurrentUser(userData));
        dispatch(fetchAllUser({ accId: accId }))
        setIsEdit(false);
    }

    const handleCancel = () => {
        dispatch(fetchAllUser({ accId: accId }))
        setIsEdit(false);
    }
    return (
        <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            form={form}
        >
            <div className='flex flex-col h-[140vh]  w-[100%]'>
                <PageHeader header={'Edit Profile'} />
                <Divider />

                <div className='relative'>
                    <button className='mb-10'>
                        <Avatar
                            size={150}
                            src={imageSrc}
                        />
                        <div className='absolute left-[10%] bottom-10'>
                            <EditOutlined />
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                        />
                    </button>
                </div>

                <Form.Item
                    initialValue={accTradeName}
                    name="accTradeName"
                    label="Company Name"
                    rules={[
                        {
                            message: "Please add company name",
                        },
                    ]}
                >
                    <Input className="input" placeholder="Company Name" />
                </Form.Item>
                <Form.Item
                    name="accRegNo"
                    initialValue={accRegNo}
                    label="Business Registration No."
                    rules={[
                        {
                            message: "Please add Business Registration No.",
                        },
                    ]}
                >
                    <Input className="input" placeholder="Business Registration No." />
                </Form.Item>
                <div className="grid grid-cols-2 lg:gap-4">
                    <Form.Item
                        name="accCounty"
                        initialValue={accCounty}
                        label="County"
                        rules={[
                            {
                                message: "Please add county",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="County" />
                    </Form.Item>
                    <Form.Item
                        name="accLocation"
                        initialValue={accLocation}
                        label="Location"
                        rules={[
                            {
                                message: "Please add location",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="Location" />
                    </Form.Item>
                </div>
                <div className="grid grid-cols-2 lg:gap-4">
                    <Form.Item
                        name="accEmail"
                        initialValue={accEmail}
                        label="Company Email"
                        rules={[
                            {
                                message: "Please add Company Email",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="Company Email" />
                    </Form.Item>
                    <Form.Item
                        name="accPhoneNumber"
                        initialValue={accPhoneNumber}
                        label="Phone Number"
                        rules={[
                            {
                                message: "Please add Phone Number",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="company Phone " />
                    </Form.Item>
                </div>
                <div className='font-[700] mb-10'>Users Details</div>
                <div className="grid grid-cols-2 lg:gap-4">
                    <Form.Item
                        name="usrFirstName"
                        initialValue={usrFirstName}
                        label="First Name"
                        rules={[
                            {
                                message: "please add First Name",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="usrLastName"
                        initialValue={usrLastName}
                        label="Last Name"
                        rules={[
                            {
                                message: "Please add user last name",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="Last Name" />
                    </Form.Item>
                </div>
                <div className="grid grid-cols-2 lg:gap-4">
                    <Form.Item
                        name="usrEmail"
                        initialValue={usrEmail}
                        label="Email"
                        rules={[
                            {
                                message: "please add Email",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="Email" disabled />
                    </Form.Item>
                    <Form.Item

                        name="usrMobileNumber"
                        initialValue={usrMobileNumber}
                        label="Phone Number"
                        rules={[
                            {
                                message: "Please add Phone Number",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="Phone Number" />
                    </Form.Item>
                </div>
                <div className="flex items-center justify-between mt-[35px]">
                    <div className="flex items-start"></div>
                    <div className="flex gap-5 items-center">
                        <button
                            className="text-Blu text-xl font-medium font-dmSans leading-normal
                     w-[168px] h-12 px-[18px] py-3 rounded-[46px] shadow justify-center items-center gap-2 inline-flex"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>

                        <button
                            className="cstm-btn" type="submit"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>

        </Form>
    )
}

export default EditAccount