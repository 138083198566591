import React, { useEffect, useRef, useState } from "react";
import { Checkbox, DatePicker, Divider, Form, Input, InputNumber, Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import WaterSource from "./WaterSource";
import StorageTanks from "./StorageTanks";
import Pipeline from "./Pipeline";
import Staff from "./Staff";
import {
  ExistenceData,
  OtherSourcesData,
  OtherSourcesRWHData,
} from "../../../data";
import { fetchWspProject } from "../../../features/wsp/wspSlice";
import { save } from "../../../features/save/saveSlice";

function ProjectDescription({ next, prev, wspApplications }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState(wspApplications);
  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projExistingOperational: formData?.projExistingOperational,
        projOtherExistingWaterSources: formData?.projOtherExistingWaterSources,
        projWspAround: formData?.projWspAround,
        projOtherWspSswp: formData?.projOtherWspSswp,
        projAverageVolumeDry: formData?.projAverageVolumeDry,
        projAverageVolWet: formData?.projAverageVolWet,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleNext();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const [subStep, setSubStep] = useState(0);

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

  // const handleNext = () => {
  //   if (subStep === 0) {
  //     setSubStep(subStep + 1);
  //   } else {
  //     next();
  //   }
  // };
  const handleNext = async () => {
    await setSubStep(subStep + 1);
    await fetchWspProjectData();
  };

  const handlePrev = async () => {
    await fetchWspProjectData();
    if (subStep === 0) {
      prev();
    } else {
      setSubStep(subStep - 1);
    }
  };

  const onChangeOther = (value, fieldName) => {  
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <>
      <div>
        {subStep === 0 && (
          <div className="flex flex-col  h-full py-[2.25rem] px-[3.88rem] justify-start items-start">
            <div className="profile_header">
              b) Project Description & Infrastructure
            </div>

            <div className="profile_sub mt-[.94rem]">
              Water source infrastructure
            </div>

            <div>
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                style={{
                  maxWidth: "100%",
                }}
                className="w-[600px] mt-[1.13rem]"
                form={form}
              >
                <div className="flex flex-col">
                  <Form.Item
                    extra={"Choose project existing and operational?"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please select service ownership",
                      },
                    ]}
                  >
                    <Select
                      name="projExistingOperational"
                      value={formData?.projExistingOperational}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projExistingOperational");
                      }}
                      placeholder="Is the project existing and operational?"
                      options={
                        ExistenceData?.length > 0 &&
                        ExistenceData?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Choose existing sources within the water project area of operation"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please select service ownership",
                      },
                    ]}
                  >
                    <Select
                      className=""
                      name="projOtherExistingWaterSources"
                      value={formData?.projOtherExistingWaterSources}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(
                          value,
                          "projOtherExistingWaterSources"
                        );
                      }}
                      placeholder="Other existing sources within the water project area of operation"
                      options={
                        OtherSourcesRWHData?.length > 0 &&
                        OtherSourcesRWHData?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Choose is the SSP within the area of an existing WSP ?"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please select service ownership",
                      },
                    ]}
                  >
                    <Select
                      name="projWspAround"
                      value={formData?.projWspAround}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projWspAround");
                      }}
                      placeholder="Is the SSP within the area of an existing WSP ?"
                      options={
                        OtherSourcesData?.length > 0 &&
                        OtherSourcesData?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Choose any other WSP or SSP operating in the same area of the water project?"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please select service ownership",
                      },
                    ]}
                  >
                    <Select
                      name="projOtherWspSswp"
                      value={formData?.projOtherWspSswp}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projOtherWspSswp");
                      }}
                      placeholder="Any other WSP or SSP operating in the same area of the water project?"
                      options={
                        OtherSourcesData?.length > 0 &&
                        OtherSourcesData?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Average dry"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "Please add average volume abstracted per day from all sources m3/day dry season",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projAverageVolumeDry"
                      value={formData?.projAverageVolumeDry}
                      className="input"
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}                
                      onChange={value => onChangeOther(value, 'projAverageVolumeDry')}
                      placeholder="Average volume abstracted per day from all sources m3/day dry season"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Average Wet"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "Please add average volume abstracted per day from all sources m3/day wet season",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projAverageVolWet"
                      value={formData?.projAverageVolWet}
                      className="input"
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}                
                      onChange={value => onChangeOther(value, 'projAverageVolWet')}
                      placeholder="Average volume abstracted per day from all sources m3/day wet season"
                    />
                  </Form.Item>

                  <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                    <button type="submit" className="cstm-btn">
                      Continue
                    </button>
                    <button onClick={() => prev()} className='border-btn mt-[.75rem]'>Previous</button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
        {subStep === 1 && <WaterSource next={handleNext} prev={handlePrev} />}
        {subStep === 2 && <StorageTanks next={handleNext} prev={handlePrev} />}
        {subStep === 3 && <Pipeline next={handleNext} prev={handlePrev} />}
        {subStep === 4 && (
          <Staff next={handleNext} prev={handlePrev} nextStep={next} />
        )}
      </div>
    </>
  );
}

export default ProjectDescription;
