import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../../assets/svg/chevron-left.svg";
import { Checkbox, Form, Input, message, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../../../ScrollToTop";
import { setOutcomeDetails } from "../../../../features/wsp_registration/wspRegSlice";
import PhoneInput from "react-phone-input-2";
import AuthPartnerSideImage from "../../../../components/auth-partner/AuthPartnerSideImage";
import indImg from "../../../../assets/img/indicator.png";
import toast from "react-hot-toast";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;

export default function OutcomeAreas() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { contactDetails, outcome } = useSelector((state) => state.wspReg);

  function handleBack() {
    navigate("/signup-partner/core-indicators");
  }

  const [data, setdata] = useState({});

  const options = [
    {
      label: "Access to clean drinking water",
      value: "accAreas1",
    },
    {
      label: "Energy transition",
      value: "accAreas2",
    },
    {
      label: "Employment",
      value: "accAreas3",
    },
    {
      label: "Climate",
      value: "accAreas4",
    },
    {
      label: "Gender",
      value: "accAreas5",
    },
    {
      label: "Entrepreneurship",
      value: "accAreas6",
    },
  ];

  const [checkedList, setCheckedList] = useState([]);
  
  const maxSelection = 2;

  const onChangeCheckbox = (checkedValues) => {
    if (checkedValues.length <= 2) {
      setCheckedList(checkedValues);
    } else {
      toast.error("You can only select a maximum of 2 options.");
    }
  };

  const onFinish = async (values) => {
    await dispatch(setOutcomeDetails(values));
    await navigate("/signup-partner/mandate");
  };

  return (
    <>
      <ScrollToTop />
      <div className="lg:grid lg:grid-cols-6 p-3 lg:p-0">
        <div className="bg-[#226cbf] w-full col-span-2 relative hidden lg:block">
          <AuthPartnerSideImage
            hideStepper={false}
            hideText={false}
            current={1}
          />
        </div>
        <div className="w-full h-[100vh] lg:col-span-4">
          <div className="flex flex-col">
            <button
              onClick={handleBack}
              className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[48px] items-center justify-center flex"
            >
              <img src={leftArrow} alt="backarrow" />
            </button>
            <div className="flex justify-center flex-col items-center">
              <img
               onClick={()=>navigate('/')}
                src={Logo}
                alt="logo"
                className="w-[211px] h-[73px] object-cover cursor-pointer"
              />
              <h3 className="signup-start mt-[3.13rem]">
                Fill in the following details
              </h3>
              <span className="signup-start-text  mt-[2.5rem]">
                Step 3 - Outcome areas
              </span>

              <div className="mt-[4.5rem] px-6 w-fit">
                <Form
                  layout="vertical"
                  ref={formRef}
                  name="control-ref"
                  onFinish={onFinish}
                  style={{
                    maxWidth: "100%",
                    width: "700px",
                  }}
                  form={form}
                  initialValues={{
                    requiredMarkValue: false,
                    ...outcome,
                  }}
                  requiredMark={false}
                >
                  <div className="partner-card w-full px-[2.69rem] py-[2.12rem]">
                    <div className="indicator-title">
                      Please select your cross cutting output or outcome areas.
                    </div>
                    <span className="!font-[400]">
                      (Select a maximum of 2){" "}
                    </span>
                    <div className="mt-[1.84rem]">
                      <Form.Item
                        name="accAreas"
                        className="w-full"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]}
                      >
                        {/* <Checkbox.Group
                          options={options}
                          className="flex flex-col gap-y-[2rem]"
                          onChange={onChangeCheckbox}
                        /> */}
                        <Checkbox.Group
                          value={checkedList}
                          className="flex flex-col gap-y-[1.81rem] ind-h1"
                          onChange={onChangeCheckbox}
                        >
                          {options.map((option) => (
                            <Checkbox
                              key={option?.value}
                              value={option?.value}
                              checked={checkedList?.includes(option?.value)}
                              disabled={
                                checkedList?.length >= maxSelection &&
                                !checkedList?.includes(option?.value)
                              }
                            >
                              {option?.label}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="w-full flex justify-center">
                    <div className="w-[10.75rem]">
                      <button type="submit" className="nxt-btn !mt-[3.75rem]">
                        Continue
                      </button>
                    </div>
                  </div>
                </Form>
              </div>

              <span className="already mt-[2.5rem]">
                Already have an account?{" "}
                <Link to="/login" className="already-text">
                  Log in
                </Link>
              </span>

              <div className="sticky mt-[5.75rem]">
                <span className="already-text !no-underline">
                  Privacy Policy • Terms and Conditions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
