import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../../assets/svg/chevron-left.svg";
import { Form, Input, Select, InputNumber, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AuthSideImage from "../../../../components/auth/AuthSideImage";
import ScrollToTop from "../../../../ScrollToTop";
import { countryPhoneCodes } from "../../../../phoneCountryCodes";
import { setBasicDetails } from "../../../../features/wsp_registration/wspRegSlice";
import PhoneInput from "react-phone-input-2";

const description = "This is a description.";

export default function BasicDetailsStepper() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { basicDetails } = useSelector((state) => state.wspReg);

  function handleBack() {
    navigate("/signup-wsp/getting-started");
  }

  const [data, setdata] = useState({});

  const handleNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      usrMobileNumber: String(e),
    }));
  };
  const handleAltNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      usrAltPhoneNo: String(e),
    }));
  };

  const onFinish = async (values) => {
    let altFullName = values?.usrAltFullNames?.split(" ");
    values.usrAltFirstName = altFullName[0];
    values.usrAltLastName =
      altFullName.length > 1
        ? altFullName?.slice(altFullName.length - 1)?.join(" ")
        : null;

    values.accTypeId = 1;
    values.usrJbrnId = 1;

    await dispatch(setBasicDetails(values));
    await navigate("/signup-wsp/business-details");
  };

  useEffect(() => {}, [basicDetails]);

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className='lg:grid lg:grid-cols-6 p-3 lg:p-0 w-full'>
        <div className='bg-[#226cbf] w-full col-span-2 relative hidden lg:block'>
          <AuthSideImage hideStepper={false} hideText={false} current={0} />
        </div>
        <div className='w-full h-[100vh] lg:col-span-4'>
          <div className='flex flex-col'>
            <button
              onClick={handleBack}
              className='border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[48px] items-center justify-center flex'
            >
              <img src={leftArrow} alt='backarrow' />
            </button>
            <div className='flex justify-center flex-col items-center'>
              <img
               onClick={()=>navigate('/')}
                src={Logo}
                alt='logo'
                className='w-[211px] h-[73px] object-cover'
              />
              <h3 className='signup-start mt-2'>Let’s get started</h3>
              <span className='signup-start-text w-[383px] mt-[10px]'>
                All we need is a few company details.
              </span>
              <span className='signup-start-text w-[383px] mt-1'>
                Then you'll be set up and able to apply for financing.
              </span>

              <div className='mt-10 p-6 w-full xl:w-fit'>
                <Form
                  layout='vertical'
                  ref={formRef}
                  name='control-ref'
                  onFinish={onFinish}
                  style={{
                    maxWidth: "100%",
                    // width: "700px",
                    marginTop: 20,
                    padding: 5,
                  }}
                  className="w-full xl:w-[700px]"
                  form={form}
                  initialValues={{
                    requiredMarkValue: false,
                    ...basicDetails,
                  }}
                  requiredMark={false}
                >
                  <Form.Item
                    extra='Full name as it appears on identification document'
                    name='usrFullNames'
                    className='w-full'
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input placeholder='Full name' className='reg-input' />
                  </Form.Item>

                  <Form.Item
                    extra='We will use this to verify your identity'
                    name='usrNationalId'
                    className='w-full'
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input
                      placeholder='National ID / Passport No.'
                      className='reg-input'
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"For example 'you@email.com'"}
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                    name='usrEmail'
                    className='w-full'
                  >
                    <Input
                      placeholder='Email'
                      type='email'
                      className='reg-input'
                    />
                  </Form.Item>

                  <Form.Item
                    extra={
                      "For example '+254 723 456 789' or ‘+254 110 123 456’"
                    }
                    name='usrMobileNumber'
                    rules={[
                      {
                        required: true,
                        message: "Please add phone no",
                      },
                    ]}
                  >
                    <PhoneInput
                      onChange={handleNumberChange}
                      country='ke'
                      onlyCountries={["ke"]}
                      countryCodeEditable={false}
                      className='reg-phone'
                    />
                  </Form.Item>

                  <Form.Item
                    extra='Give their full name'
                    name='usrAltFullNames'
                    className='w-full'
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input
                      placeholder='Name of alternate contact person'
                      className='reg-input'
                    />
                  </Form.Item>

                  <Form.Item
                    name='usrAltPhoneNo'
                    extra={
                      "For example '+254 723 456 789' or ‘+254 110 123 456’"
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please add alt phone no",
                      },
                    ]}
                  >
                    <PhoneInput
                      onChange={handleAltNumberChange}
                      country='ke'
                      onlyCountries={["ke"]}
                      countryCodeEditable={false}
                      className='reg-phone'
                    />
                  </Form.Item>

                  <div className='w-full flex justify-center'>
                    <button type='submit' className='nxt-btn'>
                      Continue
                    </button>
                  </div>
                </Form>
              </div>
              <span className='already mt-[38px] mb-[10%]'>
                Already have an account?{" "}
                <Link to='/login' className='already-text'>
                  Log in
                </Link>
              </span>

              <div className='sticky mt-10'>
                <span className='already-text !no-underline'>
                  Privacy Policy • Terms and Conditions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
