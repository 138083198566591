import React from 'react'
import MonitoringTable from './components/MonitoringTable'

const Monitoring = () => {
    return (
        <div className='flex flex-col h-screen  w-[75vw]'>
            <h1 className='font-[600] text-[22px] mb-5 text-blk'>Monitoring and Evaluation</h1>
            <div className='mt-4 text-blk text-[1.125rem] font-[600]'>M&E Report</div>
            <MonitoringTable />
        </div>
    )
}

export default Monitoring