import React, { useState } from 'react'
import MyAccount from './MyAccount'
import EditAccount from './EditAccount';

const Account = () => {
    const [isEdit, setIsEdit] = useState(false);
    return (
        <>
            {isEdit ?
                <EditAccount setIsEdit={setIsEdit} /> : <MyAccount setIsEdit={setIsEdit} />
            }
        </>
    )
}

export default Account