import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../../assets/svg/chevron-left.svg";
import { Form, Input, Select, InputNumber, Steps, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setBasicDetails } from "../../../../features/wsp_registration/wspRegSlice";
import AuthTaSideImage from "../../../../components/auth-ta/AuthTaSideImage";
import ScrollToTop from "../../../../ScrollToTop";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;
export default function BasicDetailsTaStepper() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { basicDetails } = useSelector((state) => state.wspReg);

  function handleBack() {
    navigate("/");
  }

  const [data, setdata] = useState({});

  const handleNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      usrMobileNumber: String(e),
    }));
  };
  const handleAltNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      usrAltPhoneNo: String(e),
    }));
  };

  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");
  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf,.doc,.docx",
    action: `${url}/usr/postImages.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinish = async (values) => {
    values.projRegCertFile = fileUrl;
    await dispatch(setBasicDetails(values));
    await navigate("/signup-ta/contact-details");
  };

  useEffect(() => {}, [basicDetails]);

  return (
    <>
      <ScrollToTop />
      <div className="lg:grid lg:grid-cols-6 p-3 lg:p-0">
        <div className="bg-[#226cbf] w-full col-span-2 relative hidden lg:block">
          <AuthTaSideImage hideStepper={false} hideText={false} current={0} />
        </div>
        <div className="w-full h-[100vh] lg:col-span-4">
          <div className="flex flex-col">
            <button
              onClick={handleBack}
              className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
             ml-[48px] items-center justify-center flex"
            >
              <img src={leftArrow} alt="backarrow" />
            </button>
            <div className="flex justify-center flex-col items-center">
              <img
               onClick={()=>navigate('/')}
                src={Logo}
                alt="logo"
                className="w-[211px] h-[73px] object-cover"
              />
              <h3 className="signup-start mt-[3.13rem]">
                Fill in the following details
              </h3>
              <span className="signup-start-text  mt-[2.5rem]">
                Step 1 - Basic Details
              </span>

              <div className="mt-[2.25rem] px-6 w-fit">
                <Form
                  layout="vertical"
                  ref={formRef}
                  name="control-ref"
                  onFinish={onFinish}
                  style={{
                    maxWidth: "100%",
                    width: "700px",
                    padding: 5,
                  }}
                  form={form}
                  initialValues={{
                    requiredMarkValue: false,
                    ...basicDetails,
                  }}
                  requiredMark={false}
                >
                  <Form.Item
                    extra="Organization name as it appears on legal registration"
                    name="accTradeName"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Name of organization"
                      className="reg-input"
                    />
                  </Form.Item>

                  <Form.Item
                    name="accWebsite"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please add official website",
                      },
                      {
                        type: "url",
                        message: "Please add a valid URL",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Organization website."
                      className="reg-input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"For example, BN/YYYY/123456"}
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                    name="accRegNo"
                    className="w-full"
                  >
                    <Input
                      placeholder="Business registration no."
                      className="reg-input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"For example, A012345678Z"}
                    name="accPinCert"
                    rules={[
                      {
                        required: true,
                        message: "Please add KRA pin",
                      },
                      {
                        pattern: /^[A-Z0-9]{11}$/,
                        message:
                          "Invalid Kra Pin format. A valid Kra Pin should be 11 alphanumeric characters.",
                      },
                    ]}
                  >
                    <Input placeholder="KRA PIN" className="reg-input" />
                  </Form.Item>
                  <Form.Item
                    name="projRegCertFile"
                    extra="Upload document .pdf, .doc"
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Dragger {...props}>
                      <div className="flex items-center justify-between">
                        <p className="ant-upload-text">
                          Please attach your business registration certificate
                        </p>

                        {fileName ? (
                          <span className="ant-upload-text">{fileName}</span>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_311_2606)">
                              <path
                                d="M16 16.2514L12 12.2514M12 12.2514L7.99996 16.2514M12 12.2514V21.2514M20.39 18.6414C21.3653 18.1097 22.1358 17.2683 22.5798 16.2501C23.0239 15.2318 23.1162 14.0947 22.8422 13.0181C22.5682 11.9416 21.9434 10.987 21.0666 10.3049C20.1898 9.62283 19.1108 9.25218 18 9.25145H16.74C16.4373 8.08069 15.8731 6.99378 15.0899 6.07244C14.3067 5.1511 13.3248 4.4193 12.2181 3.93206C11.1113 3.44481 9.90851 3.21481 8.70008 3.25933C7.49164 3.30385 6.30903 3.62175 5.24114 4.18911C4.17325 4.75648 3.24787 5.55855 2.53458 6.53503C1.82129 7.51151 1.33865 8.63699 1.12294 9.82684C0.90723 11.0167 0.964065 12.24 1.28917 13.4047C1.61428 14.5694 2.1992 15.6453 2.99996 16.5514"
                                stroke="#565E6C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_311_2606">
                                <rect
                                  width="24"
                                  height="24"
                                  fill="white"
                                  transform="translate(0 0.25)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                    </Dragger>
                  </Form.Item>

                  <div className="w-full flex justify-center">
                    <div className="w-[10.75rem]">
                      <button type="submit" className="nxt-btn !mt-[3.75rem]">
                        Continue
                      </button>
                    </div>
                  </div>
                </Form>
              </div>

              <span className="already mt-[2.5rem]">
                Already have an account?{" "}
                <Link to="/login" className="already-text">
                  Log in
                </Link>
              </span>

              <div className="sticky mt-[5.75rem]">
                <span className="already-text !no-underline">
                  Privacy Policy • Terms and Conditions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
