import { Link } from 'react-router-dom';
import seedlings from '../../../../assets/img/seedlings.png';

export default function NeedWaterMobile() {
	return (
		<>
			<div className='flex flex-col items-start h-[23.3125rem] w-full mt-[5.52rem] relative'>
				<div className='flex w-full flex-shrink-0 h-full'>
					<img  className='object-fill' src={seedlings} alt='seedlings' />
				</div>
				<div className='flex w-[17.4375rem] h-fit flex-col bg-[#0170BD] p-[1.25rem] absolute -bottom-[7rem]'>
					<span className='need_water_header_txt'>
						Need water infrastructure in your area?
					</span>

					<span className='need_water_txt mt-[1.62rem]'>
						Fund my water is here to meet your financial needs
					</span>

					<Link
						to='/signup-wsp/getting-started'
						className='cstm-btn mt-[2.13rem] !bg-white !text-[#0170BD]'>
						Let’s get started
					</Link>
				</div>
			</div>
		</>
	);
}
