import React, { useRef, useState } from 'react'
import { Avatar, Button, Divider, Form, Input, Modal } from 'antd';
import PageHeader from '../../../../components/PageHeader';
import { formatImgPath } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';

import { addUser, fetchAllUser } from '../../../../features/auth/authSlice';
import { uploadFile } from '../../../../features/obj/objSlice';



const AddUserModal = ({ open, setOpen }) => {

    const { accLocation, accTradeName, usrId, usrAccId, usrIcon, accId, accRegNo, accCounty, accEmail, accPhoneNumber, usrMobileNumber, usrEmail, usrLastName, usrFirstName } = useSelector((state) => state.auth.user);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [accProfile, setAccProfile] = useState(null);
    const formRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(formatImgPath(usrIcon == null ? "./myimages/OTP_IMAGES/FUND_MY_WATER/avatar.png" : usrIcon));

    const onFinish = async (data) => {
        const updatedData = { usrAccId, ...data }
        dispatch(addUser(updatedData))
        dispatch(fetchAllUser({ accId: usrAccId }))
        setOpen(false);
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const handleImageChange = async (e) => {

        let file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        const res = await dispatch(uploadFile(formData))
        setImageSrc(formatImgPath(res.payload));
        setAccProfile(res.payload);
    };
    return (
        <Modal
            title="Add User"
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={1000}
            footer={null}
        >
            <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                form={form}
            >


                <div className='flex flex-col   w-[100%]'>
                    <PageHeader header={'Add user'} />
                    <Divider />
                    <div className='relative'>
                        <button className='mb-10'>
                            <Avatar
                                size={150}
                                src={imageSrc}
                            />
                            <div className='absolute left-[15%] bottom-10'>
                                <EditOutlined />
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                            />
                        </button>
                    </div>
                    <div className="grid grid-cols-2 lg:gap-4">
                        <Form.Item
                            name="usrFirstName"
                            // initialValue={usrFirstName}
                            label="First Name"
                            rules={[
                                {
                                    message: "please add First Name",
                                },
                            ]}
                        >
                            <Input className="input" placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                            name="usrLastName"
                            // initialValue={usrLastName}
                            label="Last Name"
                            rules={[
                                {
                                    message: "Please add user last name",
                                },
                            ]}
                        >
                            <Input className="input" placeholder="Last Name" />
                        </Form.Item>
                    </div>
                    <div className="grid grid-cols-2 lg:gap-4">
                        <Form.Item
                            name="usrEmail"
                            // initialValue={usrEmail}
                            label="Email"
                            rules={[
                                {
                                    message: "Please add Company Email",
                                },
                            ]}
                        >
                            <Input className="input" placeholder="user email" />
                        </Form.Item>
                        <Form.Item
                            name="usrMobileNumber"
                            // initialValue={usrMobileNumber}
                            label="Phone Number"
                            rules={[
                                {
                                    message: "Please add Phone Number",
                                },
                            ]}
                        >
                            <Input className="input" placeholder="Phone number " />
                        </Form.Item>
                    </div>
                    <Form.Item
                        // initialValue={accTradeName}
                        name="usrNationalId"
                        label="National Id"
                        rules={[
                            {
                                message: "Please add National Id",
                            },
                        ]}
                    >
                        <Input className="input" placeholder="National Id" />
                    </Form.Item>
                    <div className="flex items-center justify-between mt-[35px]">
                        <div className="flex items-start"></div>
                        <div className="flex gap-5 items-center">
                            <button
                                className="text-Blu text-xl font-medium font-dmSans leading-normal
                     w-[168px] h-12 px-[18px] py-3 rounded-[46px] shadow justify-center items-center gap-2 inline-flex"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>

                            <button
                                className="cstm-btn" type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Form >
        </Modal >

    )
}

export default AddUserModal