import { Radio, Space, Spin, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../features/save/saveSlice';
import { useNavigate } from 'react-router-dom';

export default function ActionStep({ prev }) {
	const { user } = useSelector((state) => state.auth);
	const { newProjectObj } = useSelector((state) => state.obj);
	const { saving } = useSelector((state) => state.save);

	const [value, setValue] = useState('');

	const onChange = (e) => {
		setValue(e.target.value);
	};

	let color =
		newProjectObj?.projStatus === 'DRAFT'
			? '#4BB543'
			: newProjectObj?.projStatus === 'REJECT'
			? '#CE1821'
			: '#808080';

	useEffect(() => {}, [newProjectObj]);

	return (
		<>
			<div className='flex flex-col gap-y-[1.06rem]'>
				<div className='info_desc_card_2'>
					<div className='w-full border-b border-[#F2F4F7] pb-[.75rem]'>
						<span className='action_header_txt'>Project Status</span>
					</div>
					<Tag color={color}>
						{newProjectObj?.projStatus === 'DRAFT'
							? 'APPROVED'
							: newProjectObj?.projStatus?.toUpperCase()}
					</Tag>
				</div>

				<div className='info_desc_card_2'>
					<span className='action_header_txt'>
						Reason for Approval/Rejection
					</span>
					<TextArea
						readOnly
						value={newProjectObj?.projMemo ?? 'N/A'}
						cols={4}
						rows={6}
					/>

					{newProjectObj?.projStatus === 'REJECT' || newProjectObj?.projStatus === 'DRAFT' ? (
						<span>
							The Project was{' '}
							{newProjectObj?.projStatus === 'REJECT' ? 'rejected' : 'approved'}{' '}
							by {newProjectObj?.approverName ?? 'N/A'} From{' '}
							{newProjectObj?.bankName ?? 'N/A'}
						</span>
					) : null}
				</div>
			</div>

			<div className='mt-[8.75rem] flex flex-col'>
				<button disabled={saving} onClick={() => prev()} className='cstm-btn'>
					Previous
				</button>
			</div>
		</>
	);
}
