import React, { useEffect } from 'react';
import {
	AppstoreOutlined,
	ContainerOutlined,
	DesktopOutlined,
	MailOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	PieChartOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';
import image1 from '../assets/img/image1.png';

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type,
	};
}

const items = [
	getItem('Dashboard', 'dash', <MailOutlined />, [
		getItem(
			'Summary',
			'sum',
			null,
			[
				getItem('All', '/dashboard'),
				getItem('Property', '/dashboard/summary/property'),
				getItem('Payment', '/dashboard/summary/payment'),
			],
			'group'
		),
	]),
	getItem('Option 1', '1', <PieChartOutlined />),
	getItem('Option 2', '2', <DesktopOutlined />),
	getItem('Option 3', '3', <ContainerOutlined />),
	getItem('Navigation One', 'sub1', <MailOutlined />, [
		getItem('Option 5', '5'),
		getItem('Option 6', '6'),
		getItem('Option 7', '7'),
		getItem('Option 8', '8'),
	]),
	getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
		getItem('Option 9', '9'),
		getItem('Option 10', '10'),
		getItem('Submenu', 'sub3', null, [
			getItem('Option 11', '11'),
			getItem('Option 12', '12'),
		]),
	]),
];

const accountsDropDownItems = [
	{
		label: <a href='https://www.antgroup.com'>1st menu item</a>,
		key: '0',
	},
	{
		label: <a href='https://www.aliyun.com'>2nd menu item</a>,
		key: '1',
	},
	{
		type: 'divider',
	},
	{
		label: '3rd menu item',
		key: '3',
	},
];

export default function SideBarClosed() {
	const { collapsed } = useSelector((state) => state.setup);

	useEffect(() => {}, [collapsed]);

	return (
		<>
			<div
				style={{
					borderInlineEnd: '1px solid rgba(5, 5, 5, 0.06)',
					padding: '0.75rem',
				}}
				className='flex flex-col items-center h-[100vh] bg-nvblu'>
				<div
					style={{
						height: '3.75rem',
						display: 'flex',
						alignItems: 'center',
					}}
					className='flex'>
					<div className='flex items-center'>
						<img src={image1} className='logo' alt='image2' />
					</div>
				</div>

				<div className='flex items-center p-3 bg-nvblu mt-[2rem]'>
					<button className=''>
						<Dropdown
							menu={{
								items: accountsDropDownItems,
							}}
							trigger={['click']}
							placement='bottomLeft'>
							<div onClick={(e) => e.preventDefault()}>
								<Avatar
									src='https://ui-avatars.com/api/?name=Abzed+Tetz'
									size={50}
								/>
							</div>
						</Dropdown>
					</button>
				</div>

				<Menu
					defaultSelectedKeys={['dash', '/dashboard']}
					defaultOpenKeys={[]}
					mode='inline'
					theme='dark'
					inlineCollapsed={collapsed}
					items={items}
				/>
			</div>
		</>
	);
}
