import { Dropdown, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchWspProject } from "../../features/wsp/wspSlice";
import { formatImgPath, formatMoney, formatPath, previewPaper } from "../../utils";
import ConfirmSubmitModal from "../../components/ConfirmSubmitModal";
import { save } from "../../features/save/saveSlice";
import toast from "react-hot-toast";
import { fetchLoanTerms } from "../../features/offer/offerSlice";

function LoanOfferTable() {
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const { saving } = useSelector((state) => state.save);
  const { wspApplications } = useSelector((state) => state.wsp);
  const [search, setsearch] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { offerData, loading } = useSelector((state) => state.offer);
  console.log("offerData", offerData);

  const [prodd, setProdd] = useState();
  const [proj, setProj] = useState();
  const showModalConfirmation = async (item) => {
    await setProdd(item);
    await setIsModalOpenConfirmation(true);
  };
 
  const handleSubmit = async (item) => {
    const res = await dispatch(
      save({
        url: "/usr/saveLoanTermCondition.action",
        ltcProjId: wspApplications?.projId,
        ltcStatus: prodd,
        ltcId: proj?.ltcId,
      })
    );
    if (res?.payload?.success) {
     await  toast.success(res?.payload?.messages?.message);
     await setIsModalOpenConfirmation(false);
     await fetchLoanTermsData()
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handleView = () => {
    navigate(`/loan-offer-view/${proj?.ltcId}`);
  };

  const settingItems = [
    {
      key: "0",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[16px] flex items-center justify-center"
          onClick={() => showModalConfirmation("ACCEPTED")}
        >
          Accept Offer
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[16px] flex items-center justify-center"
          onClick={() => showModalConfirmation("REJECTED")}
        >
          Reject Offer
        </div>
      ),
    },
    // {
    //   key: "1",
    //   label: (
    //     <div
    //       className="font-dmSans text-black font-[400] text-[16px] flex items-center justify-center"
    //       onClick={handleView}
    //     >
    //       View
    //     </div>
    //   ),
    // },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const columns = [
    {
      title: "Name",
      dataIndex: "wsp",
    },
    {
      title: "Loan Amount",
      render: (item) => {
        return <div>{formatMoney(item)}</div>;
      },
      dataIndex: "ltcLoanAmnt",
    },
    {
      title: "Interest",
      dataIndex: "ltcLoanInterest",
    },
    {
      title: "Payment Term",
      dataIndex: "ltcLoanTerm",
    },
    {
      title: "TermSheet",
      render: (item) => {
        return <div className="text-[#0170BD] cursor-pointer underline" onClick={()=>previewPaper(item)}>{formatPath(item)}</div>;
      },
      dataIndex: "ltcTermsheetFile",
    },
    {
      title: "Action",
      render: (item) => {
        return (
          <>
            {/* <div className='text-[#0170BD] cursor-pointer' onClick={()=>handleView(item)}>View</div> */}
          {
            item?.ltcStatus === "ACTIVE" && (
              <button className="p-0 m-0 flex items-end" type="button" onClick={()=>setProj(item)}>
              <Dropdown
                overlayStyle={{
                  width: "250px",
                }}
                trigger={"click"}
                menu={{ items: settingItems }}
                placement="bottom"
              >
                <div className="text-[#0170BD]">Action</div>
              </Dropdown>
            </button>
            )
          }
        
          </>
        );
      },
    },
  ];


  async function fetchLoanTermsData() {
    dispatch(
      fetchLoanTerms({
        ltcProjId: wspApplications?.projId, 
      })
    );
  }

  useEffect(() => {
    fetchLoanTermsData();
  }, []);
  return (
    <>
      <div className="flex items-center lg:w-[50%] w-full">
        <Input
          value={search}
          onChange={(e) => setsearch(e.target.value)}
          placeholder="Search project"
          className="text-[16px] font-[400] flex-row-reverse w-[100%]"
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                fill="#333333"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                fill="#333333"
              />
            </svg>
          }
        />
      </div>
      <div className="mt-10">
        <Table
          className="mt-[1px] w-full"
          scroll={{
            x: 800,
          }}
          rowKey={(record) => record?.ltcId}
          columns={columns}
          dataSource={offerData}
          loading={loading}
        />
      </div>

      <ConfirmSubmitModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        content="Are you sure?"
        subContent={`Are you sure that you want to ${
          prodd === "ACCEPTED" ? "Accept" : "Reject"
        } this offer`}
        handleSubmit={handleSubmit}
        loading={saving}
      />
    </>
  );
}

export default LoanOfferTable;
