import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Spin } from "antd";
import { useRef } from "react";
import toast from "react-hot-toast";
import { save } from "../../../../features/save/saveSlice";
import { setForgotValueEmail } from "../../../../features/auth/authSlice";

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formRef = useRef(null);

  const { saving } = useSelector((state) => state.save); 
  const onFinish = async (data) => { 
    const res = await dispatch(
      save({ 
        url: `/usr/reset_password.action?phone=${data.usrEmail}`,
      })
    ); 
    if (res?.payload?.success) {
      await toast.success(res.payload?.message);
      await dispatch(setForgotValueEmail(data?.usrEmail))
      await navigate("/verify-forgot-password");
    } else {
      toast.error(res.payload?.message);
    }
  };

  return (
    <>
      <div className="w-full min-w-[24rem] h-full flex justify-center items-center">
        <div className="flex flex-col items-center mt-[7.12rem]">
          <div className="flex flex-col items-center w-full">
            <h3 className="signup-start mt-10">Forgot password</h3>
            <span className="signup-start-text text-center   mt-[.75rem]">
              Let’s reset your password
            </span>
          </div>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
              width: "24rem",
              marginTop: "3.75rem",
            }}
            className="lg:px-0 px-3"
            form={form}
          >
            <Form.Item
              label="Email address*"
              className="login-form-item"
              name="usrEmail"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input className="reg-input" />
            </Form.Item>

            <div className="flex justify-center items-center mt-[2.75rem]">
              <button disabled={saving} className="cstm-btn " type="submit">
                {saving ? <Spin /> : "Next"}
              </button>
            </div>

            <div className="mt-[44px] flex justify-center items-center">
            <span className="already ">
            Don’t have an account?{" "}
                <Link to="/login" className="already-text">
                  Log in
                </Link>
              </span>
 
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
