import PageHeader from '../../components/PageHeader';
import BreadCrumb from '../../layout/BreadCrumb';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Steps } from 'antd'; 
import RegistrationReview from './steps/RegistrationReview';
import { useNavigate } from 'react-router-dom';
 

export default function ApproveWsp() {
	const { newProjectObj } = useSelector((state) => state.obj);

	const [current, setCurrent] = useState(0);
	const navigate =  useNavigate()
	const next = () => {
		navigate('/onboard-wsp')
		// setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

    const onChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
      };

	useEffect(() => {}, [newProjectObj]);

	const breadList = [
		{
			title: 'Home',
			href: '/',
		},
		{
			title: 'New Projects',
			href: '/pipelines/new-projects',
		},
		{
			title: newProjectObj?.accTradeName?.toUpperCase(),
		},
	];

	const steps = [
		{
			key: 0,
			title: 'Registration Review', 
			content: <RegistrationReview next={next} />,
		},
		{
			key: 1,
			title: 'Expression of Interest', 
			// content: <BusinessDetailStep next={next} prev={prev} />,
		},
         
	];

	const items = steps.map((item) => ({
		key: item.key,
		title: item.title,
	}));

	return (
		<>
			<div className='w-full flex flex-col'>
				<div className='heading_1 '>Welcome, James.</div>

                <div className='heading_sub mt-[1.31rem]'>Here’s your application status</div>

				 
                    <div className='flex  mt-[3.19rem] h-full w-full'>
                        
                       <div className='new_project_steps_card '>
						<span className='step_header_txt'>
                        BOOSTER WATER
						</span>
						<span className='step_desc_txt mt-[.58rem]'>
                        Your registration is under review 
                        {/* Fill in the following section to complete your application. */}
						</span>
						<Steps
							onChange={onChange}
							direction='vertical'
							current={current}
							items={items}
							className='mt-[2.23rem]'
						/>
					</div>
                    <div className='w-[100%] new_project_info_card'>{steps[current].content}</div>
                    </div>
					
 
			</div>
		</>
	);
}
