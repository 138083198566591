import React, { useRef, useState } from "react";
import svg1 from "../../../assets/svg/svg1.svg";
import svg2 from "../../../assets/svg/svg2.svg";
import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import toast from "react-hot-toast";
import { disabledDate, formatPath } from "../../../utils";
import { save, saveFile } from "../../../features/save/saveSlice";

function FinancialDocuments({ next, prev, nextStep }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wspApplications } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }
  async function handleOpenFIle2() {
    document.getElementById("file2").click();
  }
  async function handleOpenFIle3() {
    document.getElementById("file3").click();
  }
  async function handleOpenFIle4() {
    document.getElementById("file4").click();
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projAssets: fileUrlasset,
        projAudiAccFile: fileUrlrecent,
        projAudiAccFile2: fileUrlpast,
        projAudiAccFile3: fileUrloldest,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await nextStep();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const [fileUrlasset, setfileUrlasset] = useState(
    wspApplications?.projAssets ?? ""
  );
  async function handleCancelasset() {
    await setfileUrlasset();
  }
  async function handleUploadasset(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlasset(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlrecent, setfileUrlrecent] = useState(
    wspApplications?.projAudiAccFile ?? ""
  );
  async function handleCancelrecent() {
    await setfileUrlrecent();
  }
  async function handleUploadrecent(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlrecent(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlpast, setfileUrlpast] = useState(
    wspApplications?.projAudiAccFile2 ?? ""
  );
  async function handleCancelpast() {
    await setfileUrlpast();
  }
  async function handleUploadpast(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlpast(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrloldest, setfileUrloldest] = useState(
    wspApplications?.projAudiAccFile3 ?? ""
  );
  async function handleCanceloldest() {
    await setfileUrloldest();
  }
  async function handleUploadoldest(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrloldest(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">d) Financial Information</div>

        <div className="profile_sub mt-[.94rem]">
          Please upload the following documents
        </div>
      </div>

      <div className=" px-[3.88rem]">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          style={{
            maxWidth: "100%",
          }}
          className="w-[600px] mt-[1.13rem]"
          form={form}
        >
          <div className="flex flex-col">
            <Form.Item
              rules={[
                {
                  required: false,
                  message: "Upload a list of all assets owned .pdf",
                },
              ]}
              className="w-full"
              name={"fileUrlasset"}
              extra={"Upload a list of all assets owned .pdf"}
            >
              {fileUrlasset ? (
                <>
                  <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                    <span className="text-[18px]">
                      {formatPath(fileUrlasset)}
                    </span>

                    <button
                      type="button"
                      onClick={handleCancelasset}
                      className="flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                          fill="#147CBC"
                        />
                      </svg>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="input relative" onClick={handleOpenFIle}>
                    <input
                      className="!hidden"
                      accept=".pdf"
                      id="file"
                      name="file"
                      onChange={(e) => handleUploadasset(e)}
                      type="file"
                    />
                    <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                      Upload a list of all assets owned
                    </span>
                    <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                      <img src={uplooadSimple} alt="uploadSimple" />
                    </div>
                  </div>
                </>
              )}
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: false,
                  message: "Upload PDF of most recent report(pdf) .pdf",
                },
              ]}
              className="w-full"
              name={"fileUrlrecent"}
              extra={"Upload PDF of most recent report(pdf) .pdf"}
            >
              {fileUrlrecent ? (
                <>
                  <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                    <span className="text-[18px]">
                      {formatPath(fileUrlrecent)}
                    </span>

                    <button
                      type="button"
                      onClick={handleCancelrecent}
                      className="flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                          fill="#147CBC"
                        />
                      </svg>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="input relative" onClick={handleOpenFIle2}>
                    <input
                      className="!hidden"
                      accept=".pdf"
                      id="file2"
                      name="file"
                      onChange={(e) => handleUploadrecent(e)}
                      type="file"
                    />
                    <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                      PDF of most recent report(pdf)
                    </span>
                    <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                      <img src={uplooadSimple} alt="uploadSimple" />
                    </div>
                  </div>
                </>
              )}
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: false,
                  message: "Upload PDF of past audit report(pdf) .pdf",
                },
              ]}
              className="w-full"
              name={"fileUrlpast"}
              extra={"Upload PDF of past audit report(pdf) .pdf"}
            >
              {fileUrlpast ? (
                <>
                  <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                    <span className="text-[18px]">
                      {formatPath(fileUrlpast)}
                    </span>

                    <button
                      type="button"
                      onClick={handleCancelpast}
                      className="flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                          fill="#147CBC"
                        />
                      </svg>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="input relative" onClick={handleOpenFIle3}>
                    <input
                      className="!hidden"
                      accept=".pdf"
                      id="file3"
                      name="file"
                      onChange={(e) => handleUploadpast(e)}
                      type="file"
                    />
                    <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                      PDF of past audit report(pdf)
                    </span>
                    <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                      <img src={uplooadSimple} alt="uploadSimple" />
                    </div>
                  </div>
                </>
              )}
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: false,
                  message: "Upload file .pdf",
                },
              ]}
              className="w-full"
              name={"fileUrloldest"}
              extra={"PDF of oldest audit report(pdf)"}
            >
              {fileUrloldest ? (
                <>
                  <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                    <span className="text-[18px]">
                      {formatPath(fileUrloldest)}
                    </span>

                    <button
                      type="button"
                      onClick={handleCanceloldest}
                      className="flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                          fill="#147CBC"
                        />
                      </svg>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="input relative" onClick={handleOpenFIle4}>
                    <input
                      className="!hidden"
                      accept=".pdf"
                      id="file4"
                      name="file"
                      onChange={(e) => handleUploadoldest(e)}
                      type="file"
                    />
                    <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                      PDF of oldest audit report(pdf)
                    </span>
                    <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                      <img src={uplooadSimple} alt="uploadSimple" />
                    </div>
                  </div>
                </>
              )}
            </Form.Item>

            <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
              <button type="submit" className="cstm-btn">
                {saving ? <Spin /> : "Next"}
              </button>
              <button onClick={() => prev()} className="border-btn mt-[.75rem]">
                Previous
              </button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default FinancialDocuments;
