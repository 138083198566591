import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../../assets/svg/chevron-left.svg";
import { Checkbox, Form, Input, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../../../ScrollToTop";
import {
  setContactDetails,
  setIndicatorsDetails,
} from "../../../../features/wsp_registration/wspRegSlice";
import PhoneInput from "react-phone-input-2";
import AuthPartnerSideImage from "../../../../components/auth-partner/AuthPartnerSideImage";
import indImg from "../../../../assets/img/indicator.png";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;

export default function CoreIndicators() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { contactDetails, coreIndicator } = useSelector(
    (state) => state.wspReg
  );

  function handleBack() {
    navigate("/signup-partner/basic-details");
  }

  const [data, setdata] = useState({});

  const options = [
    {
      label: (
        <div className="flex items-center gap-x-[.75rem] ml-[.56rem]">
          <img src={indImg} alt="indImg" />
          <span className="flex flex-col ind-h1">
            Access to clean drinking water
            <span className="ind-sub">
              Universal and equitable access to safe and affordable drinking
              water for all.
            </span>
          </span>
        </div>
      ),
      value: "accFocus1",
    },
    {
      label: (
        <div className="flex items-center gap-x-[.75rem] ml-[.56rem]">
          <img src={indImg} alt="indImg" />
          <span className="flex flex-col ind-h1">
            Water resource management
            <span className="ind-sub">
              Access to adequate and equitable sanitation and hygiene for all
              and end open defecation, paying special attention to the needs of
              women and girls and those in vulnerable situations.
            </span>
          </span>
        </div>
      ),
      value: "accFocus2",
    },
    {
      label: (
        <div className="flex items-center gap-x-[.75rem] ml-[.56rem]">
          <img src={indImg} alt="indImg" />
          <span className="flex flex-col ind-h1">
            Water quality
            <span className="ind-sub">
              Improve water quality by reducing pollution, eliminating dumping
              and minimizing release of hazardous chemicals and materials,
              halving the proportion of untreated wastewater, and substantially
              increasing recycling and safe reuse globally.
            </span>
          </span>
        </div>
      ),
      value: "accFocus3",
    },
  ];

  const onChangeCheckbox = (checkedValues) => {};

  const onFinish = async (values) => {
    await dispatch(setIndicatorsDetails(values));
    await navigate("/signup-partner/outcome-areas");
  };

  console.log("coreIndicator", coreIndicator);

  return (
    <>
      <ScrollToTop />
      <div className="lg:grid lg:grid-cols-6 p-3 lg:p-0">
        <div className="bg-[#226cbf] w-full col-span-2 relative hidden lg:block">
          <AuthPartnerSideImage
            hideStepper={false}
            hideText={false}
            current={1}
          />
        </div>
        <div className="w-full h-[100vh] lg:col-span-4">
          <div className="flex flex-col">
            <button
              onClick={handleBack}
              className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[48px] items-center justify-center flex"
            >
              <img src={leftArrow} alt="backarrow" />
            </button>
            <div className="flex justify-center flex-col items-center">
              <img
                onClick={()=>navigate('/')}
                src={Logo}
                alt="logo"
                className="w-[211px] h-[73px] object-cover cursor-pointer"
              />
              <h3 className="signup-start mt-[3.13rem]">
                Fill in the following details
              </h3>
              <span className="signup-start-text  mt-[2.5rem]">
                Step 2 - Core Indicators
              </span>

              <div className="mt-[4.5rem] px-6 w-fit">
                <Form
                  layout="vertical"
                  ref={formRef}
                  name="control-ref"
                  onFinish={onFinish}
                  style={{
                    maxWidth: "100%",
                    width: "700px",
                  }}
                  form={form}
                  initialValues={{
                    requiredMarkValue: false,
                    ...coreIndicator,
                  }}
                  requiredMark={false}
                >
                  <div className="partner-card w-full px-[2.69rem] py-[2.12rem]">
                    <div className="indicator-title">
                      Please select your focus area
                    </div>
                    <div className="mt-[1.84rem]">
                      <Form.Item
                        name="accFocus"
                        className="w-full"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]}
                      >
                        <Checkbox.Group
                          options={options}
                          className="flex flex-col gap-y-[2rem]"
                          onChange={onChangeCheckbox}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="w-full flex justify-center">
                    <div className="w-[10.75rem]">
                      <button type="submit" className="nxt-btn !mt-[3.75rem]">
                        Continue
                      </button>
                    </div>
                  </div>
                </Form>
              </div>

              <span className="already mt-[2.5rem]">
                Already have an account?{" "}
                <Link to="/login" className="already-text">
                  Log in
                </Link>
              </span>

              <div className="sticky mt-[5.75rem]">
                <span className="already-text !no-underline">
                  Privacy Policy • Terms and Conditions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
