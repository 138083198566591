import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import toast from "react-hot-toast";
import { obfuscateEmail } from "../../../../utils"; 
import { save } from "../../../../features/save/saveSlice";
 

function VerifyForgotEmail() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [isInputEmpty, setIsInputEmpty] = useState(true);

  const [counter, setCounter] = useState({ minutes: 0, seconds: 59 });
  const [activeResend, setActiveResend] = useState(false);

  const { forgotValueEmail } = useSelector((state) => state.auth);

 
  const resendOTPEmail = async () => {
    const res = await dispatch(
      save({
        url: `/usr/reset_password.action?phone=${forgotValueEmail}`,
      })
    );
    if (res?.payload?.success) {
      toast.success(res.payload?.message);
      setCounter({ minutes: 0, seconds: 59 })
    } else {
      toast.error(res.payload?.message);
    }
  };

  const handleBack = async () => {
    await navigate("/forgot-password");
  };
 
  const onFinish = async (data) => { 
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setIsInputEmpty(!value);
    setFormData((prevData) => ({
      ...prevData,
      usrFirstName: value,
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter.minutes === 0 && counter.seconds === 0) {
        clearInterval(interval);
      } else {
        setCounter((prevCounter) => {
          if (prevCounter.seconds === 0) {
            return { minutes: prevCounter.minutes - 1, seconds: 59 };
          } else {
            return { ...prevCounter, seconds: prevCounter.seconds - 1 };
          }
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  return (
    <>
 
      <div className="w-full h-full flex justify-center items-center">
        <div className="flex flex-col mt-[56px] w-[450px]">
          <div className="signup-start mt-10 text-center">Verify your account</div>
          <div className="signup-start-text  text-center mt-[.75rem]">We have sent an email with instructions on how to reset your password. If you cannot find an email in your inbox, check your spam.</div>

          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
            }}
            className="mt-[78px] lg:px-0 px-3"
            form={form}
          >
            
            <div className="mt-[22px] flex items-center justify-center gap-x-3">
              <div className="w-[185px]">
                <button
                  className={`cstm-btn !bg-[#fff] !text-[#374151] border border-[#D1D5DB]`}
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="w-[185px]">
                <button 
                  type="button"
                  className={`cstm-btn`} 
                  onClick={()=>navigate('/login')}
                >
                Close
                </button>
              </div>
            </div>

            <div className="mt-[66px] flex items-center justify-center typography_p_2 gap-x-1">
              Didn’t receive OTP?
              {counter.minutes === 0 && counter.seconds === 0 ? (
                <span
                  className="font-[600] typography_p_2 !text-[#0057E3] cursor-pointer"
                  onClick={resendOTPEmail}
                  disabled={counter.minutes > 0 && counter.seconds > 0}
                  type="primary"
                >
                  Resend
                </span>
              ) : (
                <span className="font-[600] typography_p_2">
                  {" "}
                  Resend in {counter.minutes.toString().padStart(2, "0")}:
                  {counter.seconds.toString().padStart(2, "0")}
                </span>
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default VerifyForgotEmail;
