import { Dropdown } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function HomeHeaderMobile() {
	const [open, setopen] = useState(false);

	function handleOpenChange() {
		setopen(!open);
	}

	const profileDropDownItems = [
		{
			label: (
				<>
					<div className='w-full flex flex-col gap-[1.25rem] px-[1rem] py-[2.25rem]'>
						<Link className='nav_item_txt' to='/signup-partner/basic-details'>
							Become a partner
						</Link>
						<Link className='nav_item_txt'>About Challenge Fund</Link>
						<Link className='nav_item_txt'>FAQ’s</Link>

						<div className='w-full flex flex-wrap items-center gap-[.87rem]'>
							<Link to='/signup-wsp/getting-started' className='border-btn'>
								Register
							</Link>
							<Link to='/login' className='cstm-btn'>
								Login
							</Link>
						</div>
					</div>
				</>
			),
			key: '0',
		},
	];

	return (
		<>
			<div className='flex items-center justify-between w-full mt-[3.12rem] bg-[#fff] px-[2.25rem]'>
				<Dropdown
					overlayStyle={{
						width: '100%',
					}}
					onOpenChange={handleOpenChange}
					open={open}
					menu={{
						items: profileDropDownItems,
					}}
					trigger={['click']}
					placement='bottom'>
					<button type='button'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='18'
							height='14'
							viewBox='0 0 18 14'
							fill='none'>
							<path
								d='M0 14H18V11.6667H0V14ZM0 8.16667H18V5.83333H0V8.16667ZM0 0V2.33333H18V0H0Z'
								fill='#045665'
							/>
						</svg>
					</button>
				</Dropdown>
			</div>
		</>
	);
}
