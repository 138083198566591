import { Link } from 'react-router-dom';
import dashedBorder from '../../assets/svg/dashedBorder.svg';

export default function Landing() {
	return (
		<>
			<div className='flex items-center px-[4.69rem] w-full'>
				<div className='flex min-w-[34.25rem] max-w-full w-fit flex-col mt-[5.81rem]'>
					<span className='landing_h1_txt'>
						Get <span className='text-[#0170BD]'>reliable</span> <br />{' '}
						financing for your <br /> water project.
					</span>

					<span className='mt-[1.75rem] landing_h2_txt'>
						Fast, collaborative and secure <br /> access to water finance.
					</span>

					<Link
						to='/signup-wsp/getting-started'
						className='cstm-btn mt-[1.75rem]'>
						Get started
					</Link>
				</div>

				<div className='landing w-full xl:min-w-[42.91569rem] max-w-full min-h-[43.06631rem] max-h-full flex justify-center items-center'>
					<div
						style={{
							backgroundImage: `url(${dashedBorder})`,
							marginTop: '3%',
							marginLeft: '4%',
						}}
						className='p-[1.98rem] flex flex-col justify-center items-center w-[27.37219rem] h-[27.37219rem] rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='80'
							height='80'
							viewBox='0 0 80 80'
							fill='none'>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M42.2744 28.0388L70 20L63.099 48.0305L54.4902 39.766L40.6035 54.2313C40.132 54.7224 39.4807 55 38.8 55C38.1193 55 37.468 54.7224 36.9965 54.2313L26.8 43.6099L11.8035 59.2313C10.8473 60.2274 9.2647 60.2597 8.26867 59.3035C7.27265 58.3473 7.24035 56.7647 8.19653 55.7687L24.9965 38.2687C25.468 37.7776 26.1193 37.5 26.8 37.5C27.4807 37.5 28.132 37.7776 28.6035 38.2687L38.8 48.8901L50.8832 36.3034L42.2744 28.0388Z'
								fill='#0170BD'
							/>
						</svg>
						<span className='landing_num_txt'>98%</span>
						<span className='landing_num_sub_txt'>
							Increase in <br />
							water coverage
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
