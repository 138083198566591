import React, { useEffect, useRef, useState } from "react";
import svg1 from "../../../assets/svg/svg1.svg";
import svg2 from "../../../assets/svg/svg2.svg";
import { Checkbox, DatePicker, Divider, Form, Select, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import toast from "react-hot-toast";
import { disabledDate, formatPath } from "../../../utils";
import moment from "moment";
import { save, saveFile } from "../../../features/save/saveSlice";

function MinutesDocuments({ next, prev }) {
  const { wspApplications } = useSelector((state) => state.wsp);

  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fileUrl, setfileUrl] = useState("");
  const [formData, setFormData] = useState();
  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  function onMonthChangerecent(value) {
    setFormData((prevData) => ({
      ...prevData,
      projMin1Date: value,
    }));
  }
  function onMonthChangepast(value) {
    setFormData((prevData) => ({
      ...prevData,
      projMin2Date: value,
    }));
  }
  function onMonthChangeold(value) {
    setFormData((prevData) => ({
      ...prevData,
      projMin3Date: value,
    }));
  }

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }
  async function handleOpenFIle2() {
    document.getElementById("file2").click();
  }
  async function handleOpenFIle3() {
    document.getElementById("file3").click();
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projMin1Date: formData?.projMin1Date,
        projMin1File: filerecent,
        projMin2Date: formData?.projMin2Date,
        projMin2File: filepast,
        projMin3Date: formData?.projMin3Date,
        projMin3File: fileold,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await next();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const [filerecent, setfilerecent] = useState(
    wspApplications?.projMin1File ?? ""
  );
  async function handleCancelrecent() {
    await setfilerecent();
  }
  async function handleUploadrecent(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfilerecent(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [filepast, setfilepast] = useState(wspApplications?.projMin2File ?? "");
  async function handleCancelpast() {
    await setfilepast();
  }
  async function handleUploadpast(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfilepast(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileold, setfileold] = useState(wspApplications?.projMin3File ?? "");
  async function handleCancelold() {
    await setfileold();
  }
  async function handleUploadold(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileold(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  return (
    <>
      <div className="flex flex-col  h-full py-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">a) Governance Information</div>

        <div className="profile_sub mt-[.94rem]">
          Minutes of last 3 committee meetings
        </div>

        <div>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
            }}
            className="w-[600px] mt-[1.13rem]"
            form={form}
          >
            <div className="flex flex-col">
              <Form.Item className="mr-6 w-full" extra={"Choose Date"}>
                <DatePicker
                  name="projMin1Date"
                  style={{
                    width: "100%",
                  }}
                  placeholder={
                    formData?.projMin1Date
                      ? moment(formData?.projMin1Date)?.format("DD-MM-YYYY")
                      : "Date of most recent committee meeting"
                  }
                  className="input"
                  format={"DD-MM-YYYY"}
                  onChange={onMonthChangerecent}
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item
                extra={"Upload PDF copy of most recent committee meetings .pdf"}
                rules={[
                  {
                    required: false,
                    message: "Upload file .pdf",
                  },
                ]}
                name={"filerecent"}
              >
                {filerecent ? (
                  <>
                    <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                      <span className="text-[18px]">
                        {formatPath(filerecent)}
                      </span>

                      <button
                        type="button"
                        onClick={handleCancelrecent}
                        className="flex items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                            fill="#147CBC"
                          />
                        </svg>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="input relative" onClick={handleOpenFIle}>
                      <input
                        className="!hidden"
                        accept=".pdf"
                        id="file"
                        name="file"
                        onChange={(e) => handleUploadrecent(e)}
                        type="file"
                      />
                      <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                        PDF copy of most recent committee meetings
                      </span>
                      <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                        <img src={uplooadSimple} alt="uploadSimple" />
                      </div>
                    </div>
                  </>
                )}
              </Form.Item>

              <Form.Item className="mr-6 w-full" extra={"Choose Date"}>
                <DatePicker
                  name="projMin2Date"
                  style={{
                    width: "100%",
                  }}
                  placeholder={
                    formData?.projMin2Date
                      ? moment(formData?.projMin2Date)?.format("DD-MM-YYYY")
                      : "Date of past committee meeting"
                  }
                  className="input"
                  format={"DD-MM-YYYY"}
                  onChange={onMonthChangepast}
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item
                extra={"Upload PDF copy of past committee meetings .pdf"}
                rules={[
                  {
                    required: false,
                    message: "Upload file .pdf",
                  },
                ]}
                name={"filepast"}
              >
                {filepast ? (
                  <>
                    <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                      <span className="text-[18px]">
                        {formatPath(filepast)}
                      </span>

                      <button
                        type="button"
                        onClick={handleCancelpast}
                        className="flex items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                            fill="#147CBC"
                          />
                        </svg>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="input relative" onClick={handleOpenFIle2}>
                      <input
                        className="!hidden"
                        accept=".pdf"
                        id="file2"
                        name="file"
                        onChange={(e) => handleUploadpast(e)}
                        type="file"
                      />
                      <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                        PDF copy of past committee meetings
                      </span>
                      <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                        <img src={uplooadSimple} alt="uploadSimple" />
                      </div>
                    </div>
                  </>
                )}
              </Form.Item>

              <Form.Item className="mr-6 w-full" extra={"Choose Date"}>
                <DatePicker
                  name="projMin3Date"
                  style={{
                    width: "100%",
                  }}
                  placeholder={
                    formData?.projMin3Date
                      ? moment(formData?.projMin3Date)?.format("DD-MM-YYYY")
                      : "Date of oldest committee meeting"
                  }
                  className="input"
                  format={"DD-MM-YYYY"}
                  onChange={onMonthChangeold}
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item
                extra={"Upload PDF copy of minutes of oldest committee meetings .pdf"}
                rules={[
                  {
                    required: false,
                    message: "Upload file .pdf",
                  },
                ]}
                name={"fileold"}
              >
                {fileold ? (
                  <>
                    <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                      <span className="text-[18px]">{formatPath(fileold)}</span>

                      <button
                        type="button"
                        onClick={handleCancelold}
                        className="flex items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                            fill="#147CBC"
                          />
                        </svg>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="input relative" onClick={handleOpenFIle3}>
                      <input
                        className="!hidden"
                        accept=".pdf"
                        id="file3"
                        name="file"
                        onChange={(e) => handleUploadold(e)}
                        type="file"
                      />
                      <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                        PDF copy of minutes of oldest committee meetings
                      </span>
                      <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                        <img src={uplooadSimple} alt="uploadSimple" />
                      </div>
                    </div>
                  </>
                )}
              </Form.Item>

              <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                <button type="submit" className="cstm-btn">
                  {saving ? <Spin /> : "Continue"}
                </button>
                <button onClick={prev} className="border-btn mt-[.75rem]">
                  Previous
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default MinutesDocuments;
