import { Link } from 'react-router-dom';
import image1 from '../../assets/img/image1.png';

export default function HomeHeader() {
	return (
		<>
			<div className='flex items-center justify-between w-full h-[6.625rem] bg-[#FDFDFD] px-[1.56rem]'>
				<div className='flex'>
					<img
						src={image1}
						alt='logo'
						className='w-[12.875rem] h-[3.9375rem]'
					/>
				</div>

				<div className='flex items-center gap-x-[5.25rem]'>
					<Link to="/signup-partner/basic-details" className='nav_item_txt'>Become a partner</Link>
					<Link className='nav_item_txt'>About Challenge Fund</Link>
					<Link className='nav_item_txt'>FAQ’s</Link>
				</div>

                <div className='flex items-center gap-x-[.87rem]'>
                     <Link to="/signup-wsp/getting-started" className='border-btn'>Register</Link>
                     <Link to="/login" className='cstm-btn'>Login</Link>
                </div>
			</div>
		</>
	);
}
