import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../../assets/svg/chevron-left.svg";
import { Form, Input, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../../../ScrollToTop";
import { setContactDetails } from "../../../../features/wsp_registration/wspRegSlice";
import PhoneInput from "react-phone-input-2";
import AuthTaSideImage from "../../../../components/auth-ta/AuthTaSideImage";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;

export default function ContactDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { contactDetails } = useSelector((state) => state.wspReg);

  function handleBack() {
    navigate("/signup-ta/basic-details");
  }

  const [data, setdata] = useState({});

  const onFinish = async (values) => {
    console.log("values step2", values);
    await dispatch(setContactDetails(values));
    navigate("/signup-ta/password-setup");
  };

  return (
    <>
      <ScrollToTop />
      <div className="lg:grid lg:grid-cols-6 p-3 lg:p-0">
        <div className="bg-[#226cbf] w-full col-span-2 relative hidden lg:block">
          <AuthTaSideImage hideStepper={false} hideText={false} current={1} />
        </div>
        <div className="w-full h-[100vh] lg:col-span-4">
          <div className="flex flex-col">
            <button
              onClick={handleBack}
              className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[48px] items-center justify-center flex"
            >
              <img src={leftArrow} alt="backarrow" />
            </button>
            <div className="flex justify-center flex-col items-center">
              <img
               onClick={()=>navigate('/')}
                src={Logo}
                alt="logo"
                className="w-[211px] h-[73px] object-cover"
              />
              <h3 className="signup-start mt-[3.13rem]">
                Fill in the following details
              </h3>
              <span className="signup-start-text  mt-[2.5rem]">
                Step 2 - Contact Person
              </span>

              <div className="mt-[2.25rem] px-6 w-fit">
                <Form
                  layout="vertical"
                  ref={formRef}
                  name="control-ref"
                  onFinish={onFinish}
                  style={{
                    maxWidth: "100%",
                    width: "700px",
                    padding: 5,
                  }}
                  form={form}
                  initialValues={{
                    requiredMarkValue: false,
                    ...contactDetails,
                  }}
                  requiredMark={false}
                >
                  <Form.Item
                    extra="For example, ‘Customer service representative’"
                    name="usrTitle"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Title of Contact Person"
                      className="reg-input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra="For example, ‘John’"
                    name="usrFirstName"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input
                      placeholder="First name of contact person"
                      className="reg-input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra="For example, Doe"
                    name="usrLastName"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last name of contact person"
                      className="reg-input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra="For example yourname@email.com"
                    name="usrEmail"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input placeholder="Email address" className="reg-input" />
                  </Form.Item>

                  <Form.Item
                    extra={
                      "For example '+254 723 456 789' or ‘+254 110 123 456’ "
                    }
                    name="usrMobileNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please add phone no",
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.length === 12) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Phone number must be 10 characters")
                          );
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      country="ke"
                      onlyCountries={["ke"]}
                      countryCodeEditable={false}
                      className="input-phone"
                    />
                  </Form.Item>

                  <div className="w-full flex justify-center">
                    <div className="w-[10.75rem]">
                      <button type="submit" className="nxt-btn !mt-[3.75rem]">
                        Continue
                      </button>
                    </div>
                  </div>
                </Form>
              </div>

              <span className="already mt-[2.5rem]">
                Already have an account?{" "}
                <Link to="/login" className="already-text">
                  Log in
                </Link>
              </span>

              <div className="sticky mt-[5.75rem]">
                <span className="already-text !no-underline">
                  Privacy Policy • Terms and Conditions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
