import React from 'react'
import DoubleBar from './DoubleBar'

const OptimizationGoal = () => {
    return (
        <div className='flex flex-col w-[100%] mt-5 p-2 bg-white'>
            <div className='text-blk p-2 font-bold text-[1rem] '>Optimization goals</div>

            <DoubleBar title={"Reduction in operation cost"} output={56} target={78} percent={75}
                svgIcon={<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <g clip-path="url(#clip0_805_13513)">
                        <path d="M17.4902 8.39719V7.875C17.4902 5.52375 13.9437 3.75 9.24023 3.75C4.5368 3.75 0.990234 5.52375 0.990234 7.875V11.625C0.990234 13.5834 3.45117 15.1397 6.99023 15.6056V16.125C6.99023 18.4762 10.5368 20.25 15.2402 20.25C19.9437 20.25 23.4902 18.4762 23.4902 16.125V12.375C23.4902 10.4344 21.1071 8.87625 17.4902 8.39719ZM21.9902 12.375C21.9902 13.6144 19.1037 15 15.2402 15C14.8905 15 14.5437 14.9878 14.2015 14.9653C16.2237 14.2284 17.4902 13.0312 17.4902 11.625V9.91313C20.2905 10.3303 21.9902 11.4628 21.9902 12.375ZM6.99023 14.0859V11.8556C7.73628 11.9528 8.48789 12.0011 9.24023 12C9.99258 12.0011 10.7442 11.9528 11.4902 11.8556V14.0859C10.7453 14.196 9.99325 14.2508 9.24023 14.25C8.48722 14.2508 7.73517 14.196 6.99023 14.0859ZM15.9902 10.3059V11.625C15.9902 12.4116 14.8268 13.2562 12.9902 13.7691V11.5781C14.2005 11.2847 15.2252 10.8478 15.9902 10.3059ZM9.24023 5.25C13.1037 5.25 15.9902 6.63562 15.9902 7.875C15.9902 9.11438 13.1037 10.5 9.24023 10.5C5.3768 10.5 2.49023 9.11438 2.49023 7.875C2.49023 6.63562 5.3768 5.25 9.24023 5.25ZM2.49023 11.625V10.3059C3.25523 10.8478 4.27992 11.2847 5.49023 11.5781V13.7691C3.65367 13.2562 2.49023 12.4116 2.49023 11.625ZM8.49023 16.125V15.7341C8.7368 15.7434 8.98617 15.75 9.24023 15.75C9.60398 15.75 9.9593 15.7378 10.308 15.7172C10.6955 15.8559 11.0901 15.9736 11.4902 16.0697V18.2691C9.65367 17.7562 8.49023 16.9116 8.49023 16.125ZM12.9902 18.5859V16.35C13.736 16.4503 14.4877 16.5004 15.2402 16.5C15.9926 16.5011 16.7442 16.4528 17.4902 16.3556V18.5859C15.9982 18.8047 14.4823 18.8047 12.9902 18.5859ZM18.9902 18.2691V16.0781C20.2005 15.7847 21.2252 15.3478 21.9902 14.8059V16.125C21.9902 16.9116 20.8268 17.7562 18.9902 18.2691Z" fill="black" />
                    </g>
                    <defs>
                        <clipPath id="clip0_805_13513">
                            <rect width="24" height="24" fill="white" transform="translate(0.240234)" />
                        </clipPath>
                    </defs>
                </svg>} />
            <DoubleBar title={"Energy Efficiency"} output={57} target={67} percent={85}
                svgIcon={<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M20.8256 11.0786C20.7972 10.9584 20.7395 10.847 20.6577 10.7545C20.576 10.6619 20.4726 10.591 20.3568 10.548L14.9559 8.52203L16.3302 1.64734C16.3614 1.48761 16.3397 1.32209 16.2685 1.17576C16.1973 1.02943 16.0804 0.910224 15.9355 0.836138C15.7906 0.762052 15.6256 0.737102 15.4653 0.765053C15.305 0.793004 15.1581 0.872339 15.0468 0.991087L4.54681 12.2411C4.46153 12.3309 4.39984 12.4405 4.36724 12.5601C4.33465 12.6796 4.33217 12.8053 4.36003 12.926C4.38788 13.0467 4.44521 13.1587 4.52688 13.2518C4.60855 13.345 4.71203 13.4164 4.82806 13.4598L10.2309 15.4858L8.86025 22.353C8.82912 22.5127 8.8508 22.6782 8.922 22.8245C8.9932 22.9709 9.11007 23.0901 9.25496 23.1642C9.39985 23.2382 9.56491 23.2632 9.72522 23.2352C9.88554 23.2073 10.0324 23.128 10.1437 23.0092L20.6437 11.7592C20.7274 11.6693 20.7878 11.5603 20.8195 11.4416C20.8512 11.3229 20.8533 11.1983 20.8256 11.0786ZM10.8487 20.0626L11.8302 15.152C11.8654 14.9779 11.8375 14.7969 11.7517 14.6413C11.6658 14.4858 11.5276 14.3658 11.3615 14.3026L6.40775 12.4417L14.3409 3.94234L13.3602 8.85296C13.3251 9.02712 13.353 9.20809 13.4388 9.36364C13.5246 9.5192 13.6629 9.63922 13.829 9.70234L18.779 11.5586L10.8487 20.0626Z" fill="black" />
                </svg>} />
            <DoubleBar title={"Water Conservation"} output={67} target={71} percent={75}
                svgIcon={<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M17.0112 4.47635C15.8532 3.13895 14.5499 1.93465 13.1253 0.885727C12.9992 0.797387 12.8489 0.75 12.695 0.75C12.541 0.75 12.3908 0.797387 12.2647 0.885727C10.8427 1.93509 9.5419 3.13937 8.38623 4.47635C5.80904 7.43604 4.44873 10.556 4.44873 13.4998C4.44873 15.6878 5.31792 17.7862 6.8651 19.3334C8.41227 20.8806 10.5107 21.7498 12.6987 21.7498C14.8868 21.7498 16.9852 20.8806 18.5324 19.3334C20.0795 17.7862 20.9487 15.6878 20.9487 13.4998C20.9487 10.556 19.5884 7.43604 17.0112 4.47635ZM12.6987 20.2498C10.9091 20.2478 9.19339 19.536 7.92795 18.2706C6.66251 17.0051 5.95072 15.2894 5.94873 13.4998C5.94873 8.13448 11.149 3.65604 12.6987 2.43729C14.2484 3.65604 19.4487 8.1326 19.4487 13.4998C19.4467 15.2894 18.7349 17.0051 17.4695 18.2706C16.2041 19.536 14.4883 20.2478 12.6987 20.2498ZM17.9384 14.3754C17.7439 15.4617 17.2213 16.4623 16.4409 17.2426C15.6605 18.0228 14.6597 18.5452 13.5734 18.7395C13.5322 18.7461 13.4905 18.7495 13.4487 18.7498C13.2606 18.7497 13.0794 18.679 12.941 18.5516C12.8025 18.4241 12.7171 18.2494 12.7015 18.0619C12.6859 17.8744 12.7414 17.6879 12.8569 17.5394C12.9724 17.3909 13.1394 17.2912 13.325 17.2601C14.8784 16.9985 16.1965 15.6804 16.46 14.1242C16.4933 13.928 16.6032 13.7531 16.7655 13.6379C16.9277 13.5228 17.1291 13.4768 17.3253 13.5101C17.5215 13.5434 17.6964 13.6533 17.8115 13.8156C17.9267 13.9779 17.9727 14.1792 17.9394 14.3754H17.9384Z" fill="black" />
                </svg>} />
        </div>
    )
}

export default OptimizationGoal