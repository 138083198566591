import aqua from "../../assets/img/aqua.png"
import rural from "../../assets/img/rural.png"
import spa from "../../assets/img/spa.png"

export default function TrustedPartners() {
	return (
		<>
			<div className='flex flex-col w-full justify-center items-center py-[3.94rem]'>
				<span className='trusted_header_txt'>Our trusted partners</span>
				<div className='w-full flex flex-wrap justify-center items-center gap-[5.69rem] mt-[1.37rem]'>
					<img src={aqua} alt='Aqua for all' />
					<img src={rural} alt='Rural focus' />
					<img src={spa} alt='Smart people africa' />
				</div>
			</div>
		</>
	);
}
