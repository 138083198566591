import React, { useEffect, useState } from 'react'
import DataCard from '../dashboard/components/DataCard'
import WaterSectionTwo from './components/WaterSectionTwo'
import ProjectTable from './components/ProjectTable'
import { useDispatch, useSelector } from 'react-redux'
import { formatNumberWithCommas } from '../../../utils/GlobalUtils'
import { fetchClientInfo, fetchCountyCount, fetchCountyDetails, fetchCountyGraph, fetchGraphForAll, fetchOverAllDetails, fetchProjectDetails } from '../../../features/dashboard/dashSlice'
import MonitorCard from '../dashboard/components/DataCard'

const WaterResource = () => {
    const dispatch = useDispatch();
    const { data, countyCount, clientInfo, projectDetail } = useSelector(state => state.dash);
    const [selected, setSelected] = useState('Total')


    const getData = async () => {
        if (selected === "Total") {
            dispatch(fetchGraphForAll())
            dispatch(fetchCountyCount())
            dispatch(fetchOverAllDetails())
            dispatch(fetchProjectDetails(selected === "Total" ? "" : selected))
        } else {
            dispatch(fetchProjectDetails(selected === "Total" ? "" : selected))
            dispatch(fetchCountyDetails(selected))
            dispatch(fetchCountyGraph(selected))
            dispatch(fetchClientInfo(selected))
        }

    }

    const total = countyCount.reduce((accumulator, currentValue) => accumulator + currentValue.totalCount, 0);
    const activeTotal = countyCount.reduce((accumulator, currentValue) => accumulator + Number(currentValue.activeCustomers), 0);
    const DataCardElement = countyCount
        .slice()
        .sort((a, b) => b.totalCount - a.totalCount)
        .slice(0, 3)
        .map(card => {
            return (
                <>
                    <button onClick={() => setSelected(card.jorgClientCounty)}>
                        <MonitorCard
                            showPercent={true}
                            selected={selected}
                            key={card.jorgClientCounty}
                            count={((card.activeCustomers / card.totalCount) * 100).toFixed(0)}
                            percent={((card.totalCount / total) * 100).toFixed(0)}
                            title={card.jorgClientCounty}
                        />
                    </button>
                </>
            )
        });



    useEffect(() => {
        getData();
    }, [selected])


    return (
        <div className='flex flex-col h-screen  w-[100%]'>
            <h1 className='font-[600] text-[22px] mb-5 text-blk'>Water sector Performance</h1>
            <div className='flex flex-row h-[20%]'>
                <button onClick={() => setSelected("Total")}>
                    <MonitorCard
                        showPercent={true}
                        selected={selected}
                        key={"Total"}
                        count={((activeTotal / total) * 100).toFixed(0)}
                        percent={((total / total) * 1).toFixed(0)}
                        title={"Total"}
                    />
                </button>
                {DataCardElement}
            </div>
            <WaterSectionTwo selected={selected} countyCount={countyCount} />
            <ProjectTable projectDetail={projectDetail} />
        </div>
    )
}
export default WaterResource