import React, { useEffect, useState } from "react";
import svg28 from "../../assets/svg/svg28.svg";
import svg29 from "../../assets/svg/svg29.svg";
import svg30 from "../../assets/svg/svg30.svg";
import { Progress } from "antd";
import { useParams } from "react-router-dom"; 
import { useDispatch, useSelector } from "react-redux"; 
import { addSpaces, dateForHumans } from "../../utils";
import ConfirmSubmitModal from "../../components/ConfirmSubmitModal";
import { save } from "../../features/save/saveSlice";
import toast from "react-hot-toast";
import { fetchSingleLoanTerms } from "../../features/offer/offerSlice";

function LoanOfferView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleOfferData } = useSelector((state) => state.offer);
  const { user } = useSelector((state) => state.auth);
  const { saving } = useSelector((state) => state.save);
  const { wspApplications } = useSelector((state) => state.wsp);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const [prodd, setProdd] = useState();


  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const showModalConfirmation = async () => {
    setIsModalOpenConfirmation(true);
  };

  const handleSubmit = async (item) => {
    const res = await dispatch(
      save({
        url: "/usr/saveLoanTermCondition.action",
        ltcProjId: wspApplications?.projId,
        ltcStatus: prodd,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      setIsModalOpenConfirmation(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchSingleLoanTermsData() {
    dispatch(
      fetchSingleLoanTerms({
        ltcId: id
      })
    );
  }

  useEffect(() => {
    fetchSingleLoanTermsData();
  }, [id]);

  console.log("singleOfferData",singleOfferData)

 

  return (
    <div className="flex lg:flex-row flex-col gap-x-10 mb-10">
      <div className="lg:w-[70%] w-[100%]">
        <div className="prod_view_subtitle">Service Details</div>

        <div className="mt-[0.5rem] border border-[#f1f1f1] rounded-[3px] p-3 ">
          <div className="grid grid-cols-2 prod_view_table gap-y-[1rem] prod_view_table_css">
            <span>Country</span>
            <span>{singleOfferData?.raCountry ?? "Kenya"}</span>

            <span>Telcos</span>
            <span>{singleOfferData?.reTelcos ?? null}</span>

            <span>Description</span>
            <span>{singleOfferData?.reDesc ?? null}</span>

            <span>Signed Letter</span>
            <span className="flex items-center text-darkGreen">
              <img src={svg28} alt="svg28" />
              Download Pdf
            </span>
          </div>
        </div>

        <div className="prod_view_subtitle mt-10">Contact Details</div>
        <div className="prod_view_table pt-8">
          Type: {singleOfferData?.reseller?.rsBusinessType}
        </div>

        <div className="prod_view_subtitle mt-10">Company Details</div>
        <div className="mt-[0.5rem] border border-[#f1f1f1] rounded-[3px] p-3 ">
          <div className="grid grid-cols-2 prod_view_table gap-y-[1rem] prod_view_table_css">
            <span>Company name</span>
            <span>{singleOfferData?.reseller?.rsCompanyName}</span>

            <span>Industry</span>
            <span>{singleOfferData?.reseller?.reIndustry ?? null}</span>

            <span>Physical Address</span>
            <span>{singleOfferData?.reseller?.reAddress ?? null}</span>

            <span>Country</span>
            <span>{singleOfferData?.reseller?.raCountry ?? "Kenya"}</span>

            <span>Postal Address</span>
            <span>{singleOfferData?.reseller?.raPostalCode}</span>

            <span>Tax Number</span>
            <span>{singleOfferData?.reseller?.vatNumber ?? null}</span>

            <span>Certificate of Incorporation</span>
            <span className="flex items-center text-darkGreen">
              <img src={svg28} alt="svg28" />
              Download Pdf
            </span>

            <span>Tax Certificate</span>
            <span className="flex items-center text-darkGreen">
              <img src={svg28} alt="svg28" />
              Download Pdf
            </span>
          </div>
        </div>

        <div className="prod_view_subtitle mt-10">Contact Person</div>

        <div className="mt-[0.5rem] border border-[#f1f1f1] rounded-[3px] p-3 ">
          <div className="grid grid-cols-2 prod_view_table gap-y-[1rem] prod_view_table_css">
            <span>Full Legal Name</span>
            <span>{singleOfferData?.reseller?.rsContactPerson ?? null}</span>

            <span>Email Address</span>
            <span>{singleOfferData?.reseller?.rsEmail ?? null}</span>

            <span>Mobile No.</span>
            <span>
              +{addSpaces(singleOfferData?.reseller?.rsPhoneNumber ?? null)}
            </span>

            <span>Office Telephone No.</span>
            <span>
              +{addSpaces(singleOfferData?.reseller?.rsPhoneNumber ?? null)}
            </span>
          </div>
        </div>
      </div>

      <div className="lg:w-[30%] w-[100%] flex flex-col">
        <div className="prod_view_subtitle">Details</div>

        <div className="prod_view_table !text-[16px] mt-[1rem]">
          Reference No.
        </div>
        <div className="prod_view_table !text-[#808080]">
          {singleOfferData?.reResellerId ?? null}
        </div>

        <div className="prod_view_table !text-[16px] mt-[1rem]">Status</div>
        <div
          className={`rounded-[1rem] p-[.25rem] ${
            singleOfferData?.reStatus == "PROCESSED"
              ? "bg-[#388E3C]"
              : "bg-[#808080]"
          }  flex items-center justify-center !text-white
           w-[220px] gap-x-[5px] prod_view_table !text-[16px]`}
        >
          <img src={svg29} alt="29" />
          {singleOfferData?.reStatus == "PENDING"
            ? "Pending"
            : singleOfferData?.reStatus == "PROCESSING"
            ? "Submitted for Review"
            : "Completed"}
        </div>

        <div className="prod_view_table !text-[16px] mt-[1.5rem]">Progress</div>
        <div>
          {" "}
          <Progress percent={30} />
        </div>

        <div className="prod_view_table !text-[16px] mt-[1rem]">
          Last Updated
        </div>
        <div className="prod_view_table !text-[16px]  ">
          {dateForHumans(singleOfferData?.reCreatedDate)}
        </div>

        {(singleOfferData?.reStatus == "PROCESSING" && user?.layer === "TOP") && (
          <>
            <div className="prod_view_subtitle mt-[2rem]">Actions</div>
            <div className="w-[220px] !text-[18px]">
              <button className="cstm-btn" onClick={showModal}>
               Issue Sender ID
              </button>
            </div>
          </>
        )}
        {(singleOfferData?.reStatus == "PENDING" && user?.layer === "TOP") && (
          <>
            <div className="prod_view_subtitle mt-[2rem]">Actions</div>
            <div className="w-[200px] !text-[18px]">
              <button className="cstm-btn" onClick={showModalConfirmation}>
                Send in Progress
              </button>
            </div>
          </>
        )}

 
      </div> 

      <ConfirmSubmitModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        content="Are you sure?"
        subContent={`Are you sure that you want to ${
          prodd === "ACCEPTED" ? "Accept" : "Reject"
        } this offer`}
        handleSubmit={handleSubmit}
        loading={saving}
      />
    </div>
  );
}

export default LoanOfferView;
