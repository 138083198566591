import React, { useRef } from 'react';
import logo from '../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Form, Input, Spin } from 'antd';
import toast from 'react-hot-toast';
import AuthSideImage from '../../../components/auth/AuthSideImage';
import {
	cleanForgotValueEmail,
	login,
	setIsLoggedIn,
} from '../../../features/auth/authSlice';
import LoginMobile from './LoginMobile';

function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { authLoading } = useSelector((state) => state.auth);

	async function handleForgotChange() {
		await dispatch(cleanForgotValueEmail());
		await navigate('/forgot-password');
	}

	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	const handleSignup = () => {
		navigate('/signup-wsp/getting-started');
	};

	const onFinish = async (data) => {
		const res = await dispatch(
			login({
				usrUsername: data?.usrUsername,
				usrEncryptedPassword: data?.usrEncryptedPassword,
			})
		);
		if (res?.payload?.success) {
			await toast.success(res?.payload?.messages?.message);
			await dispatch(setIsLoggedIn(true));
			if (res?.payload?.jsonData?.usrJbrnId == 1) {
				await navigate('/onboard-wsp');
			} else if (res?.payload?.jsonData?.usrJbrnId === 2) {
				const url = `https://ta.fundmywater.com/#/${res?.payload?.jsonData?.usrId}/${res?.payload?.jsonData?.usrAccId}`;
				window.open(url, '_self');
			} else if (res?.payload?.jsonData?.usrJbrnId === 0) {
				const url = `https://partner.fundmywater.com/#/${res?.payload?.jsonData?.usrId}/${res?.payload?.jsonData?.usrAccId}`;
				window.open(url, '_self');
			} else {
				const url = `https://impactfunder.fundmywater.com/#/impact-funder-account/${res?.payload?.jsonData?.usrId}/${res?.payload?.jsonData?.usrAccId}`;
				window.open(url, '_self');
			}
		} else {
			toast.error('Login failed, check your credentials');
		}
	};

  if (window.innerWidth <= 1024) {
		return (
			<LoginMobile />
		);
	}

	return (
		<>
			<div className='flex items-center w-full'>
				<div className='bg-[#226cbf] w-[30%] h-[100vh]  relative hidden lg:block'>
					<AuthSideImage hideStepper={true} hideText={true} />
				</div>
				<div className='w-[70%] h-[100vh]'>
					<div
						className=' ] h-full'
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}>
						<img
							src={logo}
							alt='logo'
							style={{ marginTop: '10px' }}
							className='cursor-pointer'
							onClick={() => navigate('/')}
						/>
						<div
							style={{
								color: '#171A1F',
								fontSize: '30px',
								fontFamily: 'DM Sans',
								fontWeight: '700',
								lineHeight: '46px',
								wordWrap: 'break-word',
								marginTop: '50px',
							}}>
							Welcome back
						</div>
						<div
							style={{
								texAalign: 'center',
								color: '#565E6C',
								fontSize: '18px',
								fontFamily: 'DM Sans',
								marginTop: '15px',
								fontWeight: '500',
								lineHeight: '20px',
								wordWrap: 'break-word',
							}}>
							Log in with your details below
						</div>

						<div className='flex text-start justify-start mt-[39px]'>
							<Form
								layout='vertical'
								ref={formRef}
								name='control-ref'
								onFinish={onFinish}
								className='lg:w-[436px] w-auto max-w-[100%]'
								form={form}>
								<div className='flex flex-col mb-[-23px]'>
									<Form.Item
										name='usrUsername'
										rules={[
											{
												required: true,
												message: 'Please add official email address',
											},
											{
												type: 'email',
												message: 'Please enter a valid email address',
											},
										]}>
										<Input
											type='email'
											placeholder='Email address'
											className='reg-input'
										/>
									</Form.Item>

									<Form.Item
										name='usrEncryptedPassword'
										rules={[
											{
												required: true,
												message: 'Please add password',
											},
										]}>
										<Input.Password
											placeholder='password'
											className='reg-input'
										/>
									</Form.Item>
								</div>

								<div className='flex justify-between mt-[21px] items-center'>
									<Checkbox
										onChange={onChange}
										className='!text-[18px] font-dmSans flex items-center'>
										Remember me
									</Checkbox>

									<div
										class='text-darkBlue text-[18px] font-normal font-dmSans leading-tight cursor-pointer'
										onClick={handleForgotChange}>
										Forgot password?
									</div>
								</div>

								<div className='text-center mt-[68px]'>
									<button
										className='w-[172px] h-[52px] px-5 py-2.5 bg-darkBlue rounded-[52px] justify-center items-center gap-2 inline-flex
                text-white text-[18px] font-medium font-dmSans leading-normal'
										type='submit'>
										{authLoading ? <Spin /> : 'Log in'}
									</button>
								</div>

								<div className='mt-[44px] flex justify-center'>
									<span className='text-lightBlue text-[18px] font-normal font-dmSans leading-tight'>
										Don't have an account yet?{' '}
									</span>
									<span
										className='text-darkBlue ml-1 text-[18px] font-normal font-dmSans underline leading-tight cursor-pointer'
										onClick={handleSignup}>
										Sign up now
									</span>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
