import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  dashLoading: false,
  user: {},
  isActive: false,
  data: [],
  countyCount: [],
  graphData: [],
  clientInfo: [],
  overAllDetail: [],
  countyDetail: [],
  projectDetail: [],
};

export const fetchClientInfo = createAsyncThunk(
  "dashSlice/fetchClientInfo",
  async (data) => {
    const res = await axios.get(
      `${url}/dash/dashboardApis.action?link=http://102.217.125.92/exp/fetchCustomersPerCountyPerClientAccounts.action?County=${data}&Status=`
    );
    return res.data;
  }
);

export const fetchCountyCount = createAsyncThunk(
  "dashSlice/fetchCountyCount",
  async () => {
    const res = await axios.get(
      `${url}/dash/dashboardApis.action?link=http://102.217.125.92/exp/fetchCountyAccounts.action?County=&Status=`
    );
    return res.data;
  }
);

export const fetchCountyGraph = createAsyncThunk(
  "dashSlice/fetchCountyGraph",
  async (data) => {
    const res = await axios.get(
      `${url}/dash/dashboardApis.action?link=http://102.217.125.92/exp/fetchGraphDataCounty.action?County=${data}&Status=`
    );

    return res.data;
  }
);

export const fetchGraphForAll = createAsyncThunk(
  "dashSlice/fetchGraphForAll",
  async (data) => {
    const res = await axios.get(
      `${URL}/dash/dashboardApis.action?link=http://102.217.125.92/exp/fetchGraphDataAll.action?County=&Status=`
    );
    return res.data;
  }
);

export const fetchOverAllDetails = createAsyncThunk(
  "dashSlice/fetchOverAllDetails",
  async (data) => {
    const res = await axios.get(
      `${URL}/dash/dashboardApis.action?link=http://102.217.125.92/exp/fetchData.action?County=&Status=`
    );
    return res.data;
  }
);

export const fetchCountyDetails = createAsyncThunk(
  "dashSlice/fetchCountyDetails",
  async (data) => {
    const res = await axios.get(
      `${URL}/dash/dashboardApis.action?link=http://102.217.125.92/exp/fetchDataCounty.action?County=${data}&Status=`
    );
    return res.data;
  }
);

export const fetchProjectDetails = createAsyncThunk(
  "dashSlice/fetchProjectDetails",
  async (data) => {
    const res = await axios.get(
      `${URL}/dash/dashboardApis.action?link=http://102.217.125.92/exp/fetchDataPerClientPerCounty.action?County=${data}&Status=`
    );
    return res.data;
  }
);

export const dashSlice = createSlice({
  name: "dash",
  initialState,
  reducers: {
    addSingleProjectData: (state, action) => {
      state.overAllDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientInfo.pending, (state) => {
        state.dashLoading = true;
      })
      .addCase(fetchClientInfo.fulfilled, (state, action) => {
        state.dashLoading = false;
        state.clientInfo = action.payload.data.result;
        console.log(
          state.clientInfo,
          "______________________DATA PROVIDED____________________________-"
        );
      })
      .addCase(fetchClientInfo.rejected, (state) => {
        state.dashLoading = false;
      })
      .addCase(fetchCountyCount.pending, (state) => {
        state.dashLoading = true;
      })
      .addCase(fetchCountyCount.fulfilled, (state, action) => {
        state.countyCount = [];
        state.dashLoading = false;
        state.countyCount = action.payload.data.result;
      })
      .addCase(fetchCountyCount.rejected, (state) => {
        state.dashLoading = false;
      })

      .addCase(fetchGraphForAll.pending, (state) => {
        state.dashLoading = true;
      })
      .addCase(fetchGraphForAll.fulfilled, (state, action) => {
        state.dashLoading = false;
        state.graphData = action.payload.data.result;
      })
      .addCase(fetchGraphForAll.rejected, (state) => {
        state.dashLoading = false;
      })
      .addCase(fetchCountyGraph.pending, (state) => {
        state.dashLoading = true;
      })
      .addCase(fetchCountyGraph.fulfilled, (state, action) => {
        state.dashLoading = false;
        state.graphData = action.payload.data.result;
      })
      .addCase(fetchCountyGraph.rejected, (state) => {
        state.dashLoading = false;
      })

      //OverAll Details
      .addCase(fetchOverAllDetails.pending, (state) => {
        state.dashLoading = true;
      })
      .addCase(fetchOverAllDetails.fulfilled, (state, action) => {
        state.dashLoading = false;
        state.overAllDetail = action.payload.data.result;
      })
      .addCase(fetchOverAllDetails.rejected, (state) => {
        state.dashLoading = false;
      })
      //County Details
      .addCase(fetchCountyDetails.pending, (state) => {
        state.dashLoading = true;
      })
      .addCase(fetchCountyDetails.fulfilled, (state, action) => {
        state.dashLoading = false;
        state.overAllDetail = action.payload.data.result;
      })
      .addCase(fetchCountyDetails.rejected, (state) => {
        state.dashLoading = false;
      })

      //Project Details
      .addCase(fetchProjectDetails.pending, (state) => {
        state.dashLoading = true;
      })
      .addCase(fetchProjectDetails.fulfilled, (state, action) => {
        state.dashLoading = false;
        state.projectDetail = action.payload.data.result;
      })
      .addCase(fetchProjectDetails.rejected, (state) => {
        state.dashLoading = false;
      });
  },
});

export default dashSlice.reducer;
export const { addSingleProjectData } = dashSlice.actions;
