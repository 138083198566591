import { Col, Row } from 'antd';
import image1 from '../../../../assets/img/image1.png';
import { Link } from 'react-router-dom';

export default function FooterMobile() {
	return (
		<>
			<div className='bg-[#22262F] mt-[2.5rem] py-[4rem] px-[2.25rem]'>
				<div className='need_help_card w-full flex flex-col'>
					<div className='flex flex-col'>
						<img
							style={{
								width: '10.5rem',
								height: '3.9375rem',
								flexShrink: 0,
							}}
							src={image1}
							alt='fmw logo'
						/>
						<span className='need_help_header_txt mt-[.94rem]'>
							Need some help?
						</span>
						<span className='need_help_txt mt-[.62rem] w-full'>
							We will provide detailed information about our services, types of
							work, and top projects. We will calculate the cost and prepare a
							commercial proposal.
						</span>
					</div>
				</div>

				<div className='mt-[3rem]'>
					<a
						target='_blank'
						rel={'noreferrer'}
						href='mailto:fundmywater@spa-limited.com'
						className='cstm-btn'>
						Reach out to us
					</a>
				</div>

				<div className='mt-[3rem] w-full grid grid-cols-1 gap-[1.75rem]'>
					<div className='w-full grid grid-cols-3 gap-[2rem]'>
						<div className='flex flex-col gap-y-[.25rem]'>
							<span className='footer_header_txt mb-[1.25rem]'>REGISTER</span>
							<Link to='/' className='footer_txt'>
								Home
							</Link>
							<span className='footer_txt'>Our work</span>
							<span className='footer_txt'>Our mission</span>
						</div>

						<div className='flex flex-col gap-y-[.25rem]'>
							<span className='footer_header_txt mb-[1.25rem]'>PROJECTS</span>
							<Link to='/signup-wsp/getting-started' className='footer_txt'>
								Support my project
							</Link>
						</div>

						<div className='flex flex-col gap-y-[.25rem]'>
							<span className='footer_header_txt mb-[1.25rem]'>
								registration
							</span>
							<Link to='/signup-ta/basic-details' className='footer_txt'>
								Technical advisors
							</Link>
							<Link to='/' className='footer_txt'>
								Impact Funder
							</Link>
							<Link to='/signup-partner/basic-details' className='footer_txt'>
								Partners
							</Link>
						</div>
					</div>

					<div className='flex flex-col gap-y-[.25rem]'>
						<span className='footer_header_txt mb-[1.25rem]'>LOCATION</span>
						<span className='footer_txt w-[10rem]'>
							Fair Acres Rd, Hekima <br /> Close, Building 48 <br /> Karen,
							Nairobi
						</span>
					</div>

					<div className='flex flex-col gap-y-[.25rem]'>
						<span className='footer_header_txt mb-[1.25rem]'>Info</span>
						<Link to='/' className='footer_txt'>
							FAQ’s
						</Link>
						<Link to='/' className='footer_txt'>
							Terms and Conditions
						</Link>
						<Link to='/' className='footer_txt'>
							Privacy Policy
						</Link>
					</div>

					<div className='flex flex-col gap-y-[.25rem]'>
						<div className='w-1/2 flex justify-end'>
							<span
								style={{
									width: '2.8125rem',
									height: '0.0625rem',
									opacity: 0.25,
									background: 'var(--White, #FFF)',
								}}
								className='footer_header_txt mb-[1.25rem]'></span>
						</div>
						<span className='footer_txt'>+254 724 985933</span>
						<span className='footer_txt'>fundmywater@spa-limited.com</span>
					</div>
				</div>

				<div className='w-full mt-[3rem] flex items-center'>
					<span className='footer_txt_end w-full'>
						© FundMyWater. <br /> All Rights Reserved. 2023
					</span>

					<div className='w-full flex justify-end'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='60'
							height='60'
							viewBox='0 0 60 60'
							fill='none'>
							<circle opacity='0.2' cx='30' cy='30' r='29.5' stroke='white' />
							<path
								d='M30 37L30 22M30 22L26 26M30 22L34 26'
								stroke='white'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>
					</div>
				</div>
			</div>
		</>
	);
}
