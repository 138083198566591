import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { offset } from 'dom-helpers';


// const generateRandomData = ({ graphData }) => {
//     const months = [
//         { name: 'Jan', fullName: 'January' },
//         { name: 'Feb', fullName: 'February' },
//         { name: 'Mar', fullName: 'March' },
//         { name: 'Apr', fullName: 'April' },
//         { name: 'May', fullName: 'May' },
//         { name: 'Jun', fullName: 'June' },
//         { name: 'Jul', fullName: 'July' },
//         { name: 'Aug', fullName: 'August' },
//         { name: 'Sep', fullName: 'September' },
//         { name: 'Oct', fullName: 'October' },
//         { name: 'Nov', fullName: 'November' },
//         { name: 'Dec', fullName: 'December' }
//     ];

//     let target = 4500;
//     let outcome = 4000;
//     const data = months.map((month, index) => {
//         if (index > 0) {
//             target += Math.floor(Math.random() * 1000);
//             outcome += Math.floor(Math.random() * 1000);
//         }
//         return { month: month.name, fullName: month.fullName, target, outcome };
//     });

//     return data;
// };

// const data = graphData;

// const data = [
//     { month: 'January', target: 4500, outcome: 4000 },
//     { month: 'February', target: 4000, outcome: 3000 },
//     { month: 'March', target: 3500, outcome: 2000 },
//     { month: 'April', target: 3000, outcome: 2780 },
//     { month: 'May', target: 2500, outcome: 1890 },
//     { month: 'June', target: 3000, outcome: 2390 },
//     { month: 'July', target: 4000, outcome: 3490 },
//     { month: 'August', target: 3500, outcome: 2000 },
//     { month: 'September', target: 3000, outcome: 2780 },
//     { month: 'October', target: 2500, outcome: 1890 },
//     { month: 'November', target: 3000, outcome: 2390 },
//     { month: 'December', target: 4000, outcome: 3490 }
// ];



const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">{payload.value}</text>
        </g>
    );
};

const ImpactGraph = ({ graphData }) => (
    <ResponsiveContainer width="100%" height={400}>
        <LineChart data={graphData} margin={{ top: 50, right: 30, left: 20, bottom: 30 }}>
            <CartesianGrid vertical={false} horizontal={true} />
            <XAxis dataKey="month" label={{ value: 'Months', position: 'insideBottom', offset: -18 }} tick={<CustomizedAxisTick />} />
            <YAxis axisLine={false} label={{ value: 'Population Served', offset: -10, angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            {/* <Legend layout="horizontal" verticalAlign="top" align="center" /> */}
            <Line type="monotone" dataKey="totalCount" stroke="#8884d8" strokeWidth={3} dot={false} />
            <Line type="monotone" dataKey="activeCustomers" stroke="#82ca9d" strokeWidth={3} dot={false} />
        </LineChart>
    </ResponsiveContainer>
);

export default ImpactGraph;





