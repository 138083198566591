import React from 'react'
import svg1 from '../../../assets/svg/svg1.svg'

function RegistrationReview({next}) {
  return (
    <>
    <div className='flex flex-col justify-center items-center text-center h-full'>
        <img src={svg1} alt="svg1"/>

        {/* <div className='review_header_txt mt-[1.72rem]'>Your registration is under review</div> 
        <div className='review_desc_txt flex flex-col mt-[.88rem]'>Your registration has been submitted & will be reviewed by our team.  
            <span>You will be notified if any extra information is needed.</span>
        </div> */}


        <div className='review_header_txt mt-[1.85rem]'>Your account registration has been approved</div> 
        <div className='review_desc_txt  mt-[.62rem]'>You may proceed to the next section  
        </div>

        <div className='mt-[3.25rem]'>
				<button onClick={() => next()} className='cstm-btn'>Next</button>
			</div>
    </div>
    </>
  )
}

export default RegistrationReview