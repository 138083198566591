import { Progress } from 'antd'
import React from 'react'

const PerformanceBar = ({ percent, output, title, svgIcon }) => {
    const conicColors = {
        '0%': '#0170BD',
        '25%': '#0170BD',
        '50%': '#0873B9',
        '100%': '#DEEFFF',
    };
    return (
        <div className="flex flex-col w-[80%]">
            <div className='flex justify-between  flex-row ml-2 my-4'>
                <div className='w-[100%]'>
                    <div className="flex flex-row justify-between w-[70%]">
                        <span className="text-blk font-bold text-[0.75rem]">{title}</span>
                    </div>
                    <Progress
                        percent={percent}
                        strokeColor={conicColors}
                        showInfo={false}
                        trailColor={"#DEEFFF"}

                    />
                </div>
                <div className='flex flex-col justify-center ml-4  w-[10%]'>
                    <div>
                        {svgIcon}
                    </div>
                    <div className='flex flex-row text-blk text-[0.875rem] font-[600]'>
                        {output}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PerformanceBar