import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  newProjectObj: {},
  projectObj: {},
};

export const uploadFile = createAsyncThunk(
  "objSlice/uploadFile",
  async (formData) => {
    const res = await axiosInstance
      .post(`${url}/usr/postImages.action`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data.jsonData);
    return res;
  }
);

export const objSlice = createSlice({
  name: "obj",
  initialState,
  reducers: {
    setNewProjectObj: (state, action) => {
      state.newProjectObj = action.payload;
    },
    setProjectObj: (state, action) => {
      state.projectObj = action.payload;
    },
  },
});

export default objSlice.reducer;
export const { setNewProjectObj, setProjectObj } = objSlice.actions;
