import React, { useEffect, useRef, useState } from "react";
import svg1 from "../../../assets/svg/svg1.svg";
import svg2 from "../../../assets/svg/svg2.svg";
import { Checkbox, DatePicker, Divider, Form, Select, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import toast from "react-hot-toast";
import { disabledDate, formatPath, previewPaper } from "../../../utils";
import moment from "moment";
import svg3 from "../../../assets/svg/svg3.svg";
import svg4 from "../../../assets/svg/svg4.svg";
import image3 from "../../../assets/img/image3.png";
import svg5 from "../../../assets/svg/svg5.svg";
import { save, saveFile } from "../../../features/save/saveSlice";
import { ManagementToolData } from "../../../data";
import {
  deleteManagementTool,
  fetchManagementTool,
} from "../../../features/management-tool/mngtSlice";
import DeleteModal from "../../../components/DeleteModal";

function ManagementTool({ next, prev, nextStep }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wspApplications } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);
  const { loading, mngtToolData } = useSelector((state) => state.mngt);

  const [data, setdata] = useState({});

  function handleSelectChange(value, formName) {
    setdata((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }

  const [showAdd, setShowAdd] = useState(false);
  const handleAdd = () => {
    console.log("heheh");
    setShowAdd(true);
  };

  const handleCancel = () => {
    setShowAdd(false);
  };
  const handleSave = async (data) => {
    const res = await dispatch(
      save({
        url: "/usr/saveManagementTool.action",
        mtProjId: wspApplications?.projId,
        mtType: data?.mtType,
        mtFile: file,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res.payload?.messages?.message);
      await form.resetFields();
      await fetchManagementToolData();
      await setfile("");
      await setShowAdd(false);
    } else {
      await toast.error(res.payload?.messages?.message);
    }
  };

  const [file, setfile] = useState();
  async function handleCancelFile() {
    await setfile();
  }
  async function handleUploadFile(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfile(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  async function fetchManagementToolData() {
    dispatch(
      fetchManagementTool({
        projId: wspApplications?.projId,
      })
    );
  }

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [prodd, setProdd] = useState();
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };
  const content = `Are you sure you want to delete ${prodd?.mtType}?`;

  const handleDelete = async() => {
    const res =await dispatch(
      deleteManagementTool({
        mtId: prodd?.mtId,
      })
    ); 
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await setIsModalOpenDelete(false);
      await fetchManagementToolData()
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(() => {
    fetchManagementToolData();
  }, []);

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">a) Governance Information</div>
      </div>

      {showAdd ? (
        <div className=" px-[3.88rem]">
          <div className="profile_sub mt-[.94rem]">Add a management tool</div>
          <div>
            <Form
              layout="vertical"
              ref={formRef}
              name="control-ref"
              onFinish={handleSave}
              style={{
                maxWidth: "100%",
              }}
              className="w-[600px] mt-[1.13rem]"
              form={form}
            >
              <div className="flex flex-col">
                <Form.Item
                  name="mtType"
                  extra={"Choose Option"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select tool",
                    },
                  ]}
                >
                  <Select
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "mtType");
                    }}
                    placeholder="Select management Tool"
                    options={
                      ManagementToolData?.length > 0 &&
                      ManagementToolData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>

                <Form.Item
                  extra={"Upload file .pdf"}
                  rules={[
                    {
                      required: true,
                      message: "Upload file .pdf",
                    },
                  ]}
                  name={"file"}
                >
                  {file ? (
                    <>
                      <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                        <span className="text-[18px]">{formatPath(file)}</span>

                        <button
                          type="button"
                          onClick={handleCancelFile}
                          className="flex items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                              fill="#147CBC"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input relative" onClick={handleOpenFIle}>
                        <input
                          className="!hidden"
                          accept=".pdf"
                          id="file"
                          name="file"
                          onChange={(e) => handleUploadFile(e)}
                          type="file"
                        />
                        <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                          Copy of document
                        </span>
                        <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                          <img src={uplooadSimple} alt="uploadSimple" />
                        </div>
                      </div>
                    </>
                  )}
                </Form.Item>

                <div className="mt-[2.9rem] mb-[4.56rem] flex gap-x-5 justify-end items-center text-center">
                  <button onClick={handleCancel} className="cancel-btn">
                    Cancel
                  </button>
                  <button type="submit" className="save-btn">
                    {saving ? <Spin /> : "Save"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <>
          {mngtToolData && mngtToolData?.length > 0 ? (
            <>
              <div className="px-[3.88rem] ">
                <div className="profile_sub mt-[.94rem]">
                  You may add multiple management tools
                </div>
                <div className="flex items-center justify-between mt-[2.19rem]">
                  <div className=" mngt-details-black"> Management Details</div>
                  <div
                    className="mngt-details-blue flex items-center gap-x-2 cursor-pointer"
                    onClick={handleAdd}
                  >
                    <img src={image3} alt="image3" />
                    Add Management Tool
                  </div>
                </div>

                {mngtToolData &&
                  mngtToolData?.map((item) => (
                    <div
                      className="mt-[1.69rem] rounded-[.5rem] bg-white w-full p-[.75rem] flex justify-between items-center"
                      onClick={() => setProdd(item)}
                    >
                      <div className="flex flex-col">
                        <div className="mngt-card-header">{item?.mtType}</div>
                        <div
                          className="mngt-card-file"
                          // onClick={previewPaper(item?.mtFile)}
                        >
                          {formatPath(item?.mtFile)}
                        </div>
                      </div>

                      <div
                        className="w-[1.25rem] h-[1.25rem] flex justify-center items-center mngt-minus cursor-pointer"
                        onClick={showModalDelete}
                      >
                        <img src={svg5} alt="svg5" />
                      </div>
                    </div>
                  ))}

                <div className="mt-[3.5rem] mb-[3.5rem] flex flex-col justify-center items-center text-center">
                  <button onClick={() => nextStep()} className="cstm-btn">
                    {saving ? <Spin /> : "Continue"}
                  </button>
                  <button
                    onClick={() => prev()}
                    className="border-btn mt-[.75rem]"
                  >
                    Previous
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className="profile_sub mt-[.94rem] px-[3.88rem] ">
                Share your management tools, such as Strategic or Marketing
                Plans.{" "}
              </div>
              <div className="mt-[4.62rem] flex flex-col h-full text-center w-full items-center justify-center">
                <img src={svg3} alt="svg3" />
                <div className="mngt-tool mt-[1.89rem]">
                  Add a management tool
                </div>
                <div className="mt-[1.5rem]">
                  <button
                    onClick={handleAdd}
                    className="cstm-btn flex items-center gap-x-1"
                  >
                    <img src={svg4} alt="svg4" />
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDelete}
        content={content}
        title="Delete Management Tool"
        loading={loading}
      />
    </>
  );
}

export default ManagementTool;
