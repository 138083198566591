import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { dateForHumans, disabledDate, numberWithCommas, removeCommas } from "../../../utils";
import FinancialSummary from "./FinancialSummary";
import Auditor from "./Auditor";
import Bank from "./Bank";
import FinancialDocuments from "./FinancialDocuments";
import numeral from "numeral";
import { save } from "../../../features/save/saveSlice";
import { BillingData } from "../../../data";
import toast from "react-hot-toast";
import { fetchWspProject } from "../../../features/wsp/wspSlice";

function FinancialPosition({ next, prev, wspApplications }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);

  const [fileUrl, setfileUrl] = useState("");
  const [formData, setFormData] = useState(wspApplications); 

  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  function onMonthChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      projFinDate: value,
    }));
  }

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  // const onChange = (e) => {
  //   if(e.target.value < 0){
  //     toast.error("Value should be greater than 0")
  //     return
  //   }
  //   let { name, value } = e.target; 
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const onChange = (e) => {
    let { name, value } = e.target;
    
    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, '');
  
    if (value < 0) {
      toast.error("Value should be greater than 0");
      return;
    }
  
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeOther = (value, fieldName) => {  
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  // const onChange = (e) => {
  //   let { name, value } = e.target;
  //   const cleanedValue = value.replace(/[^0-9.]/g, "");
  //   const numericValue = Number(cleanedValue).toString();
  //   const formattedValue = numeral(numericValue).format("0,0");

  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: formattedValue,
  //   }));
  // };

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

  const [subStep, setSubStep] = useState(0);
  // const handleNext = () => {
  //   if (subStep === 0) {
  //     setSubStep(subStep + 1);
  //   } else {
  //     next();
  //   }
  // };
  const handleNext = async () => {
    await setSubStep(subStep + 1);
    await fetchWspProjectData();
  };

  const handlePrev = async () => {
    await fetchWspProjectData();
    if (subStep === 0) {
      prev();
    } else {
      setSubStep(subStep - 1);
    }
  };
 
  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projFinDate: formData?.projFinDate,
        projHandCash: removeCommas(formData?.projHandCash),
        projBankCash: removeCommas(formData?.projBankCash),
        projTotalDebtors: removeCommas(formData?.projTotalDebtors),
        projLiquidAssets:
          parseFloat(formData?.projHandCash || 0) +
          parseFloat(formData?.projBankCash || 0) +
          parseFloat(formData?.projTotalDebtors || 0),
        projLiabilities: formData?.projLiabilities,
        projNetFinances:
          parseFloat(formData?.projHandCash || 0) +
          parseFloat(formData?.projBankCash || 0) +
          parseFloat(formData?.projTotalDebtors || 0) -
          parseFloat(formData?.projLiabilities || 0),
        projBillingSystem: formData?.projBillingSystem,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleNext();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <div>
        {subStep === 0 && (
          <div className="flex flex-col  h-full py-[2.25rem] px-[3.88rem] justify-start items-start">
            <div className="profile_header">d) Financial Information</div>

            <div className="profile_sub mt-[.94rem]">
              Summary of Financial Position
            </div>

            <div>
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                style={{
                  maxWidth: "100%",
                }}
                className="w-[600px] mt-[1.13rem]"
                form={form}
              >
                <div className="flex flex-col">
                  <Form.Item className="mr-6 w-full" extra={"Choose date"}>
                    <DatePicker
                      name="projFinDate"
                      style={{
                        width: "100%",
                      }}
                      placeholder={
                        formData?.projFinDate
                          ? dateForHumans(formData?.projFinDate) 
                          : "As on dd-mm-yyyy"
                      }
                      className="input"
                      format={"DD-MM-YYYY"}
                      onChange={onMonthChange} 
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Enter cash in hand (petty cash)"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please add cash in hand (petty cash)",
                      },
                    ]}
                  >
                    <InputNumber 
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}                
                      required
                      name="projHandCash"
                      value={formData?.projHandCash}
                      placeholder="Cash in hand (petty cash)"  
                      onChange={value => onChangeOther(value, 'projHandCash')}
                      className="input "
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Enter cash in bank account(s)"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please add cash in bank account(s)",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0} 
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}    
                      onChange={value => onChangeOther(value, 'projBankCash')}            
                      name="projBankCash"
                      value={formData?.projBankCash}
                      placeholder="Cash in bank account(s)"   
                      className="input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Enter total debtors (Money owed to project) (KES)"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "Please add total debtors (Money owed to project) (KES)",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projTotalDebtors"
                      value={formData?.projTotalDebtors}
                      placeholder="Total debtors (Money owed to project) (KES)"
                      min={0} 
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}    
                      onChange={value => onChangeOther(value, 'projTotalDebtors')}    
                      className="input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Total liquid assets [(a) + (b) + (c)] (KES)"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "Please add total liquid assets [(a) + (b) + (c)] (KES)",
                      },
                    ]}
                  >
                    <Input
                      name="projLiquidAssets"
                      readOnly={true}
                      value={numberWithCommas(
                        parseFloat(formData?.projHandCash || 0) +
                        parseFloat(formData?.projBankCash || 0) +
                        parseFloat(formData?.projTotalDebtors || 0)
                      )}
                      placeholder="Total liquid assets [(a) + (b) + (c)] (KES)"
                      className="input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Enter liabilities (Money owed by project to others) (KES)"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "Please add liabilities (Money owed by project to others) (KES)",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projLiabilities"
                      value={formData?.projLiabilities}
                      placeholder="Liabilities (Money owed by project to others) (KES)"
                      min={0} 
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}    
                      onChange={value => onChangeOther(value, 'projLiabilities')}    
                      className="input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Enter financial position [(d) - (e)] (KES)"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "Please add net financial position [(d) - (e)] (KES)",
                      },
                    ]}
                  >
                    <Input
                      readOnly={true}
                      name="projNetFinances"
                      value={numberWithCommas(
                        parseFloat(formData?.projHandCash || 0) +
                        parseFloat(formData?.projBankCash || 0) +
                        parseFloat(formData?.projTotalDebtors || 0) -
                        parseFloat(formData?.projLiabilities || 0)
                      )}
                      placeholder="Net financial position [(d) - (e)] (KES)"
                      className="input"
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Choose what type of billing system do you use?"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "What type of billing system do you use?",
                      },
                    ]}
                  >
                    <Select
                      name="projBillingSystem"
                      value={formData?.projBillingSystem}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projBillingSystem");
                      }}
                      placeholder="What type of billing system do you use?"
                      options={
                        BillingData?.length > 0 &&
                        BillingData?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>

                  <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                    <button type="submit" className="cstm-btn">
                      {saving ? <Spin /> : "Continue"}
                    </button>
                    <button
                      onClick={() => prev()}
                      className="border-btn mt-[.75rem]"
                    >
                      Previous
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
        {subStep === 1 && (
          <FinancialSummary next={handleNext} prev={handlePrev} />
        )}
        {subStep === 2 && <Auditor next={handleNext} prev={handlePrev} />}
        {subStep === 3 && <Bank next={handleNext} prev={handlePrev} />}
        {subStep === 4 && (
          <FinancialDocuments
            next={handleNext}
            prev={handlePrev}
            nextStep={next}
          />
        )}
      </div>
    </>
  );
}

export default FinancialPosition;
