import React, { useRef, useState } from "react";
import { Form, Input, Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatMoney, numberWithCommas } from "../../../utils";
import FinancingTable from "./tables/FinancingTable";
 
function ProjectDetailsFinancing({ prev, next, nextStep }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wspApplications } = useSelector((state) => state.wsp);
 

  const onFinish = async (data) => { 
  };

const loanPercentage = wspApplications?.projLoanAmount ? 0.2 * wspApplications?.projLoanAmount : ''
 
 

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">e) Proposed Project Details</div>

        <div className="profile_sub mt-[.94rem]">Financing for project</div>

        <div className="profile_sub mt-[2.06rem]">
        Your contribution should not be less than {formatMoney(loanPercentage)}, which is 20% of the total loan required
        </div>
        <div className="mt-[.56rem]">
        <FinancingTable/>
        </div>
      </div>

      <div className=" px-[3.88rem]">
        <div>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
            }}
            className="w-[600px] mt-[1.13rem]"
            form={form}
          > 
            <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
              <button onClick={next} className="cstm-btn">
                Next
              </button>
              <button onClick={prev} className="border-btn mt-[.75rem]">
                Previous
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default ProjectDetailsFinancing;
