import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,
  bankData: [],
};
 
export const fetchBank = createAsyncThunk(
  "bankSlice/fetchBank",
  async (data) => {
    const response = await axios.get(
      `${url}/usr/fetchBankInfos.action?projId=${data?.projId}`
    ); 
    return response.data?.data?.result;
  }
);
export const deleteBank = createAsyncThunk(
	"bankSlice/deleteBank",
	async (data) => {
	  const response = await axios.get(
		`${url}/usr/deleteBankInfo.action?bId=${data?.bId}`
	  );  
	  return response.data;
	}
  );

  

export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchBank.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBank.fulfilled, (state, action) => {
        state.loading = false;
        state.bankData = action.payload;
      })
      
      .addCase(fetchBank.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteBank.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBank.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBank.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default bankSlice.reducer;
export const {} = bankSlice.actions;
