import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Radio, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import { formatPath } from "../../../utils";
import { save, saveFile } from "../../../features/save/saveSlice";

const items = [
  {
    title: "Tank A",
    number: "PVC",
    contract: "1000m3",
  },
  {
    title: "Tank B",
    number: "PVC",
    contract: "1000m3",
  },
];
function GridReference({ prev, next, nextStep }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);
  const { wspApplications, loading } = useSelector((state) => state.wsp);

  const [formData, setFormData] = useState(wspApplications);

  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  async function handleOpenFIle() {
    document.getElementById("file").click();
  }

  const [fileUrl, setfileUrl] = useState(
    wspApplications?.projMapAreaFile ?? ""
  );
  async function handleCancelFile() {
    await setfileUrl();
  }
  async function handleUpload(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrl(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projLat: formData?.projLat,
        projLong: formData?.projLong,
        projMapAreaFile: fileUrl,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await nextStep();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">
          b) Project Description & Infrastructure
        </div>
        <div className="mt-[.94rem]">
          {" "}
          <Radio className="mngt-tool" checked={true}>
            Commercial Connection
          </Radio>
        </div>

        <div className="mngt-tool mt-[1.44rem]">
          Grid reference of office / infrastructure
        </div>
      </div>

      <div className=" px-[3.88rem]">
        <div>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
            }}
            className="w-[600px] mt-[1.13rem]"
            form={form}
          >
            <div className="flex flex-col">
              <Form.Item
                extra={"Enter latitude"}
                className=""
                rules={[
                  {
                    required: true,
                    message: "Add latitude",
                  },
                ]}
              >
                <Input
                  name="projLat"
                  value={formData.projLat}
                  onChange={onChange}
                  placeholder="Latitude"
                  className="input"
                />
              </Form.Item>

              <Form.Item
                extra={"Enter longitude"}
                className=""
                rules={[
                  {
                    required: true,
                    message: "Add longitude",
                  },
                ]}
              >
                <Input
                  name="projLong"
                  value={formData?.projLong}
                  onChange={onChange}
                  placeholder="Longitude"
                  className="input"
                />
              </Form.Item>

              <Form.Item
                extra={"Upload .pdf"}
                rules={[
                  {
                    required: false,
                    message: "Upload file .pdf",
                  },
                ]}
                name={"gdcFileUrl"}
              >
                {fileUrl ? (
                  <>
                    <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                      <span className="text-[18px]">{formatPath(fileUrl)}</span>

                      <button
                        type="button"
                        onClick={handleCancelFile}
                        className="flex items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                            fill="#147CBC"
                          />
                        </svg>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="input relative" onClick={handleOpenFIle}>
                      <input
                        className="!hidden"
                        accept=".pdf"
                        id="file"
                        name="file"
                        onChange={(e) => handleUpload(e)}
                        type="file"
                      />
                      <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                        Map of service area (PDF)
                      </span>
                      <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                        <img src={uplooadSimple} alt="uploadSimple" />
                      </div>
                    </div>
                  </>
                )}
              </Form.Item>

              <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                <button type="submit" className="cstm-btn">
                  {saving ? <Spin /> : "Next"}
                </button>
                <button
                  onClick={() => prev()}
                  className="border-btn mt-[.75rem]"
                >
                  Previous
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default GridReference;
