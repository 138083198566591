import React, { useEffect, useRef, useState } from "react";
import { Checkbox, DatePicker, Divider, Form, Select, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import toast from "react-hot-toast";
import { disabledDate, formatPath } from "../../../utils";
import svg6 from "../../../assets/svg/svg6.svg";
import image3 from "../../../assets/img/image3.png";
import svg4 from "../../../assets/svg/svg4.svg";
import svg7 from "../../../assets/svg/svg7.svg";
import svg8 from "../../../assets/svg/svg8.svg";
import DeleteModal from "../../../components/DeleteModal";
import {
  deleteWaterSource,
  fetchWaterSource,
} from "../../../features/water-source/wtrSrcSlice";
import { save, saveFile } from "../../../features/save/saveSlice";
import {
  MasterMeterData,
  PowerData,
  ReliabilityData,
  SysytemTypeData,
  WRA,
  WaterQualityData,
  WaterSourceData,
} from "../../../data";

function WaterSource({ next, prev }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wtrSrcData, loading } = useSelector((state) => state.wtr);
  const { wspApplications } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);

  const [fileUrl, setfileUrl] = useState("");

  const [prodd, setProdd] = useState();

  const [formData, setFormData] = useState(prodd);

  useEffect(() => {
    setFormData(prodd);
  }, [prodd]);

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }

  const [showAdd, setShowAdd] = useState(false);
  const handleAdd = async () => {
    await setShowAdd(true);
    await setProdd("");
  };

  const handleEdit = async (item) => {
    await setShowAdd(true);
    await setProdd(item);
  };

  const handleCancel = () => {
    setShowAdd(false);
  };
  const handleSave = async (data) => {
    const res = await dispatch(
      save({
        url: "/usr/saveWaterSource.action",
        wsId:prodd?.wsId ? prodd?.wsId : null,
        wsProjId: wspApplications?.projId,
        wsSource: formData?.wsSource,
        wsReliability: formData?.wsReliability,
        wsQuality: formData?.wsQuality,
        wsQualityFile: fileQuality,
        wsMasterMtr: formData?.wsMasterMtr,
        wsMtrFile: fileMeter,
        wsWra: formData?.wsWra,
        wsSystem: formData?.wsSystem,
        wsPower: formData?.wsPower?.join(','),
        wsPowerFile: filePump,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res.payload?.messages?.message);
      await form.resetFields();
      await fetchWaterSourceData();
      await setfileQuality("");
      await setfileMeter("");
      await setfilePump("");
      await setShowAdd(false);
    } else {
      await toast.error(res.payload?.messages?.message);
    }
  };
  const [fileQuality, setfileQuality] = useState(prodd?.wsQualityFile ?? "");
  useEffect(()=>{
    setfileQuality(formData?.wsQualityFile ?? "")
  },[formData?.wsQualityFile ?? ""])
  async function handleCancelFileQuality() {
    await setfileQuality();
  }
  async function handleUploadQuality(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileQuality(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }
 
  const [fileMeter, setfileMeter] = useState(formData?.wsMtrFile ?? "");
  useEffect(()=>{
    setfileMeter(formData?.wsMtrFile ?? "")
  },[formData?.wsMtrFile ?? ""])
 
  async function handleCancelFileMeter() {
    await setfileMeter();
  }
  async function handleUploadMeter(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileMeter(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [filePump, setfilePump] = useState(formData?.wsPowerFile ?? "");
  useEffect(()=>{
    setfilePump(formData?.wsPowerFile ?? "")
  },[formData?.wsPowerFile ?? ""])
  async function handleCancelPump() {
    await setfilePump();
  }
  async function handleUploadPump(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfilePump(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  async function fetchWaterSourceData() {
    dispatch(
      fetchWaterSource({
        projId: wspApplications?.projId,
      })
    );
  }

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };
  const content = `Are you sure you want to delete ${prodd?.wsSource}?`;

  const handleDelete = async () => {
    const res = await dispatch(
      deleteWaterSource({
        wsId: prodd?.wsId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await setIsModalOpenDelete(false);
      await fetchWaterSourceData();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(() => {
    fetchWaterSourceData();
  }, []);

 

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">
          b) Project Description & Infrastructure
        </div>
      </div>

      {showAdd ? (
        <div className=" px-[3.88rem]">
          <div className="profile_sub mt-[.94rem]">Add water source</div>
          <div>
            <Form
              layout="vertical"
              ref={formRef}
              name="control-ref"
              onFinish={handleSave}
              style={{
                maxWidth: "100%",
              }}
              className="w-[600px] mt-[1.13rem]"
              form={form}
            >
              <div className="flex flex-col">
                <Form.Item
                  extra={"Choose water source"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select water source",
                    },
                  ]}
                >
                  <Select
                    name="wsSource"
                    value={formData?.wsSource}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "wsSource");
                    }}
                    placeholder="Water source"
                    options={
                      WaterSourceData?.length > 0 &&
                      WaterSourceData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>
                <Form.Item
                  extra={"Choose reliability of water source"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select water source reliability",
                    },
                  ]}
                >
                  <Select
                    name="wsReliability"
                    value={formData?.wsReliability}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "wsReliability");
                    }}
                    placeholder="Reliability of water source"
                    options={
                      ReliabilityData?.length > 0 &&
                      ReliabilityData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>
                <Form.Item
                  extra={"Choose water quality at source"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select water quality at source",
                    },
                  ]}
                >
                  <Select
                    name="wsQuality"
                    value={formData?.wsQuality}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "wsQuality");
                    }}
                    placeholder="Water quality at source"
                    options={
                      WaterQualityData?.length > 0 &&
                      WaterQualityData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>
                <Form.Item
                  extra={"Upload PDF of water quality analysis of source water .pdf"}
                  rules={[
                    {
                      required: false,
                      message: "Upload file .pdf",
                    },
                  ]}
                  name={"fileQuality"}
                >
                  {fileQuality ? (
                    <>
                      <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                        <span className="text-[18px]">
                          {formatPath(fileQuality)}
                        </span>

                        <button
                          type="button"
                          onClick={handleCancelFileQuality}
                          className="flex items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                              fill="#147CBC"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input relative" onClick={handleOpenFIle}>
                        <input
                          className="!hidden"
                          accept=".pdf"
                          id="file"
                          name="file"
                          onChange={(e) => handleUploadQuality(e)}
                          type="file"
                        />
                        <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                          PDF of water quality analysis of source water
                        </span>
                        <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                          <img src={uplooadSimple} alt="uploadSimple" />
                        </div>
                      </div>
                    </>
                  )}
                </Form.Item>

                <Form.Item
                  extra={"Choose Option"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message:
                        "Please select if master meter installed and functional",
                    },
                  ]}
                >
                  <Select
                    name="wsMasterMtr"
                    value={formData?.wsMasterMtr}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "wsMasterMtr");
                    }}
                    placeholder="Is a master meter installed and functional ?"
                    options={
                      MasterMeterData?.length > 0 &&
                      MasterMeterData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>
                {formData?.wsMasterMtr === "Yes" && (
                  <Form.Item
                    extra={"Upload file .pdf"}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileMeter"}
                  >
                    {fileMeter ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileMeter)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileMeter}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file"
                            name="file"
                            onChange={(e) => handleUploadMeter(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            PDF of water Meter analysis of source water
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>
                )}

                <Form.Item
                  extra={"Choose Option"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message:
                        "Please select if there a water regulatory authority (WRA) abstraction permit",
                    },
                  ]}
                >
                  <Select
                    name="wsWra"
                    value={formData?.wsWra}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "wsWra");
                    }}
                    placeholder="Is there a water regulatory authority (WRA) abstraction permit ?"
                    options={
                      WRA?.length > 0 &&
                      WRA?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>

                <Form.Item
                  extra={"Choose Option"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select type of system",
                    },
                  ]}
                >
                  <Select
                    name="wsSystem"
                    value={formData?.wsSystem}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "wsSystem");
                    }}
                    placeholder="Type of system a pumped or gravity system or hybrid?"
                    options={
                      SysytemTypeData?.length > 0 &&
                      SysytemTypeData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>
                {(formData?.wsSystem == "Pumped" ||
                  formData?.wsSystem == "Hybrid = Pumped + Gravity") && (
                  <>
                    <Form.Item
                      extra={"Choose type of power used"}
                      className=""
                      rules={[
                        {
                          required: true,
                          message: "Please select type of power",
                        },
                      ]}
                    >
                              <Select
                 mode="multiple"
                 name="wsPower"
                 allowClear
                 placeholder={
                   "select type of power"
                 }
                 size="large"
                 onChange={(value) => {
                   handleSelectChange(value, "wsPower");
                 }}
                 value={formData?.wsPower}
                 className=""
                 dropdownRender={(menu) => (
                   <>
                     <Space
                       style={{
                         padding: "8px 8px 4px",
                       }}
                     >
                       <span className="select-info mt-1">
                         You may choose one or more of the following
                       </span>
                     </Space>
                     <Divider
                       style={{
                         margin: "8px 0",
                       }}
                     />
                     {menu}
                   </>
                 )}
                 menuItemSelectedIcon={({ isSelected }) => (
                   <>
                     {isSelected ? (
                       <Checkbox checked={true}></Checkbox>
                     ) : (
                       <Checkbox checked={false}></Checkbox>
                     )}
                   </>
                 )}
               >
                 <Select.Option 
                   value={"Solar"}
                 >
                   Solar
                 </Select.Option>
                 <Select.Option value={"Wind"}>Wind</Select.Option>
                 <Select.Option value={"Electricity"}>Electricity</Select.Option>
                 <Select.Option value={"Fuel"}>
                 Fuel
                 </Select.Option>
                 
               </Select>
                      {/* <Select
                        name="wsPower"
                        value={formData?.wsPower}
                        className=""
                        allowClear
                        style={{
                          width: "100%",
                        }}
                        onChange={(value) => {
                          handleSelectChange(value, "wsPower");
                        }}
                        placeholder="Type of power used"
                        options={
                          PowerData?.length > 0 &&
                          PowerData?.map((item) => ({
                            value: item?.value,
                            label: item?.label,
                          }))
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label?.toLocaleLowerCase() ?? "").includes(
                            input?.toLocaleLowerCase()
                          )
                        }
                        onDropdownVisibleChange={() => {
                          // fetchResellerData();
                        }}
                      /> */}
                    </Form.Item>
                    <Form.Item
                      extra={"Upload file .pdf"}
                      rules={[
                        {
                          required: false,
                          message: "Upload file .pdf",
                        },
                      ]}
                      name={"filePump"}
                    >
                      {filePump ? (
                        <>
                          <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                            <span className="text-[18px]">
                              {formatPath(filePump)}
                            </span>

                            <button
                              type="button"
                              onClick={handleCancelPump}
                              className="flex items-center"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                              >
                                <path
                                  d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                  fill="#147CBC"
                                />
                              </svg>
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="input relative"
                            onClick={handleOpenFIle}
                          >
                            <input
                              className="!hidden"
                              accept=".pdf"
                              id="file"
                              name="file"
                              onChange={(e) => handleUploadPump(e)}
                              type="file"
                            />
                            <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                              picture of water pump
                            </span>
                            <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                              <img src={uplooadSimple} alt="uploadSimple" />
                            </div>
                          </div>
                        </>
                      )}
                    </Form.Item>
                  </>
                )}
                <div className="mt-[2.9rem] mb-[4.56rem] flex gap-x-5 justify-end items-center text-center">
                  <button onClick={handleCancel} className="cancel-btn">
                    Cancel
                  </button>
                  <button type="submit" className="save-btn">
                    {saving ? <Spin /> : "Save"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <>
          {wtrSrcData && wtrSrcData?.length > 0 ? (
            <div className="px-[3.88rem] ">
              <div className="profile_sub mt-[.94rem]">
                You may add multiple water sources
              </div>
              <div className="flex items-center justify-between mt-[2.19rem]">
                <div className=" mngt-details-black">Water Sources</div>
                <div className="mngt-details-blue flex items-center gap-x-2 cursor-pointer"
                onClick={handleAdd}>
                  <img src={image3} alt="image3" />
                  Add Water Source
                </div>
              </div>

              {wtrSrcData &&
                wtrSrcData?.map((item) => (
                  <div
                    className="mt-[1.69rem] rounded-[.5rem] bg-white w-full p-[.75rem]"
                    onClick={() => setProdd(item)}
                  >
                    <div className="flex items-center justify-between">
                      <div className="mngt-card-header">{item?.wsSource}</div>
                      <div className="flex items-center gap-x-1 justify-end cursor-pointer">
                        <img
                          src={svg7}
                          alt="svg7"
                          onClick={() => handleEdit(item)}
                        />
                        <img src={svg8} alt="svg8" onClick={showModalDelete} />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-y-[.75rem] mt-2">
                      <div className="wtr-srce-title">Reliability</div>
                      <div className="wtr-srce-content">
                        {item?.wsReliability ?? 'N/A'}
                      </div>

                      <div className="wtr-srce-title">Quality</div>
                      <div className="wtr-srce-content">{item?.wsQuality ?? 'N/A'}</div>

                      <div className="wtr-srce-title">Quality File</div>
                      <div className="mngt-card-file">
                        {formatPath(item?.wsQualityFile) ?? 'N/A'}
                      </div>

                      <div className="wtr-srce-title">Meter present</div>
                      <div className="wtr-srce-content">
                        {item?.wsMasterMtr ?? 'N/A'}
                      </div>

                      <div className="wtr-srce-title">Meter File</div>
                      <div className="mngt-card-file">
                        {formatPath(item?.wsMtrFile) ?? 'N/A'}
                      </div>

                      <div className="wtr-srce-title">Permit</div>
                      <div className="wtr-srce-content">{item?.wsWra ?? 'N/A'}</div>

                      <div className="wtr-srce-title">System</div>
                      <div className="wtr-srce-content">{item?.wsSystem ??  'N/A'}</div>

                      <div className="wtr-srce-title">Power</div>
                      <div className="wtr-srce-content">{item?.wsPower ?? 'N/A'}</div>

                      <div className="wtr-srce-title">Pump File</div>
                      <div className="mngt-card-file">
                        {formatPath(item?.wsPowerFile) ?? 'N/A'}
                      </div>
                    </div>
                  </div>
                ))}

              <div className=" mt-[3.5rem] mb-[3.5rem] flex flex-col justify-center items-center text-center">
                <button onClick={next} className="cstm-btn">
                  Continue
                </button>
                <button
                  onClick={() => prev()}
                  className="border-btn mt-[.75rem]"
                >
                  Previous
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="profile_sub mt-[.94rem] px-[3.88rem] ">
                Share your water sources below. You may add more than one
                source.
              </div>
              <div className="mt-[4.62rem] flex flex-col h-full text-center w-full items-center justify-center">
                <img src={svg6} alt="svg6" />
                <div className="mngt-tool mt-[1.88rem]">Add a water source</div>
                <div className="mt-[1.5rem]">
                  <button
                    onClick={handleAdd}
                    className="cstm-btn flex items-center gap-x-1"
                  >
                    <img src={svg4} alt="svg4" />
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDelete}
        content={content}
        title="Delete Water Source"
        loading={loading}
      />
    </>
  );
}

export default WaterSource;
