import React, { useEffect } from "react";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../assets/img/image1.png";

export default function SideBarOpen() {
  const navigate = useNavigate();

  const { collapsed } = useSelector((state) => state.setup);

  const handleNavigation = (e) => {
    navigate(e.key);
  };

  const items = [
    {
      key: "/onboard-wsp",
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='26'
          height='26'
          viewBox='0 0 26 26'
          fill='none'
        >
          <path
            opacity='0.35'
            d='M12.9997 12.9998C15.9912 12.9998 18.4163 10.5747 18.4163 7.58317C18.4163 4.59163 15.9912 2.1665 12.9997 2.1665C10.0081 2.1665 7.58301 4.59163 7.58301 7.58317C7.58301 10.5747 10.0081 12.9998 12.9997 12.9998Z'
            fill='#7E93AE'
          />
          <path
            d='M19.5 16.25H6.5C4.70492 16.25 3.25 17.7049 3.25 19.5C3.25 21.2951 4.70492 22.75 6.5 22.75H19.5C21.2951 22.75 22.75 21.2951 22.75 19.5C22.75 17.7049 21.2951 16.25 19.5 16.25Z'
            fill='#7E93AE'
          />
        </svg>
      ),
      label: "Applications",
    },
    // {
    //   key: "/messages",
    //   icon: (
    //     <svg
    //       xmlns='http://www.w3.org/2000/svg'
    //       width='26'
    //       height='26'
    //       viewBox='0 0 26 26'
    //       fill='none'
    //     >
    //       <path
    //         opacity='0.35'
    //         d='M13.0003 23.8332C18.9834 23.8332 23.8337 18.9829 23.8337 12.9998C23.8337 7.01675 18.9834 2.1665 13.0003 2.1665C7.01724 2.1665 2.16699 7.01675 2.16699 12.9998C2.16699 18.9829 7.01724 23.8332 13.0003 23.8332Z'
    //         fill='#7E93AE'
    //       />
    //       <path
    //         d='M13.6269 4.35618C11.1114 4.17634 8.64791 5.09609 6.87125 6.87276C5.09458 8.64943 4.17808 11.1108 4.35575 13.6263C4.67316 18.1351 8.59483 21.6668 13.2813 21.6668H15.1663C15.7643 21.6668 16.2497 21.1825 16.2497 20.5834C16.2497 19.9843 15.7643 19.5001 15.1663 19.5001H13.2813C9.72691 19.5001 6.75533 16.8535 6.517 13.4746C6.38375 11.5853 7.07166 9.73709 8.40416 8.40459C9.73558 7.07209 11.5837 6.38959 13.4752 6.51634C16.8531 6.75468 19.4997 9.72734 19.4997 13.2818V14.0834C19.4997 14.6814 19.0132 15.1668 18.4163 15.1668C17.8194 15.1668 17.333 14.6814 17.333 14.0834V13.0001C17.333 10.6103 15.3895 8.66676 12.9997 8.66676C10.6098 8.66676 8.66633 10.6103 8.66633 13.0001C8.66633 15.3899 10.6098 17.3334 12.9997 17.3334C14.1372 17.3334 15.1663 16.8838 15.9398 16.1634C16.5367 16.873 17.4197 17.3334 18.4163 17.3334C20.2082 17.3334 21.6663 15.8753 21.6663 14.0834V13.2818C21.6663 8.59526 18.1347 4.67251 13.6269 4.35618ZM12.9997 15.1668C11.8047 15.1668 10.833 14.195 10.833 13.0001C10.833 11.8052 11.8047 10.8334 12.9997 10.8334C14.1946 10.8334 15.1663 11.8052 15.1663 13.0001C15.1663 14.195 14.1946 15.1668 12.9997 15.1668Z'
    //         fill='#7E93AE'
    //       />
    //     </svg>
    //   ),
    //   label: "Messages",
    // },
    {
      key: "3",
      label: "OFFERS",
      children: [
        {
          key: "/loan-offers",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Loan Offers",
        },
      ],
    },
    {
      key: "6",
      label: "PROJECT DEVELOPMENT",
      children: [
        {
          key: "/invoice-list",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Project Development",
        },
      ],
    },

    {
      key: "/timelines",
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='26'
          height='26'
          viewBox='0 0 26 26'
          fill='none'
        >
          <path
            opacity='0.35'
            d='M13.0003 23.8332C18.9834 23.8332 23.8337 18.9829 23.8337 12.9998C23.8337 7.01675 18.9834 2.1665 13.0003 2.1665C7.01724 2.1665 2.16699 7.01675 2.16699 12.9998C2.16699 18.9829 7.01724 23.8332 13.0003 23.8332Z'
            fill='#7E93AE'
          />
          <path
            d='M13.6269 4.35618C11.1114 4.17634 8.64791 5.09609 6.87125 6.87276C5.09458 8.64943 4.17808 11.1108 4.35575 13.6263C4.67316 18.1351 8.59483 21.6668 13.2813 21.6668H15.1663C15.7643 21.6668 16.2497 21.1825 16.2497 20.5834C16.2497 19.9843 15.7643 19.5001 15.1663 19.5001H13.2813C9.72691 19.5001 6.75533 16.8535 6.517 13.4746C6.38375 11.5853 7.07166 9.73709 8.40416 8.40459C9.73558 7.07209 11.5837 6.38959 13.4752 6.51634C16.8531 6.75468 19.4997 9.72734 19.4997 13.2818V14.0834C19.4997 14.6814 19.0132 15.1668 18.4163 15.1668C17.8194 15.1668 17.333 14.6814 17.333 14.0834V13.0001C17.333 10.6103 15.3895 8.66676 12.9997 8.66676C10.6098 8.66676 8.66633 10.6103 8.66633 13.0001C8.66633 15.3899 10.6098 17.3334 12.9997 17.3334C14.1372 17.3334 15.1663 16.8838 15.9398 16.1634C16.5367 16.873 17.4197 17.3334 18.4163 17.3334C20.2082 17.3334 21.6663 15.8753 21.6663 14.0834V13.2818C21.6663 8.59526 18.1347 4.67251 13.6269 4.35618ZM12.9997 15.1668C11.8047 15.1668 10.833 14.195 10.833 13.0001C10.833 11.8052 11.8047 10.8334 12.9997 10.8334C14.1946 10.8334 15.1663 11.8052 15.1663 13.0001C15.1663 14.195 14.1946 15.1668 12.9997 15.1668Z'
            fill='#7E93AE'
          />
        </svg>
      ),
      label: "Timelines",
    },
    // {
    //   key: "4",
    //   label: "DEAL ROOM",
    //   children: [
    //     {
    //       key: "/viable-projects",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Viable projects",
    //     },
    //     {
    //       key: "/bids",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Bids",
    //     },
    //     {
    //       key: "/deals",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Deals",
    //     },
    //   ],
    // },
    // {
    //   key: "5",
    //   label: "PROJECTS",
    //   children: [
    //     {
    //       key: "/loan-perfection",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Loan perfection",
    //     },
    //     {
    //       key: "/project-development",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Project development",
    //     },
    //     {
    //       key: "/post-implementation",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Post implementation",
    //     },
    //     {
    //       key: "/outcome-verification",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Outcome verification",
    //     },
    //   ],
    // },
    // {
    //   key: "3",
    //   label: "IMPACT DASHBOARD",
    //   children: [
    //     {
    //       key: "/dashboard",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Dashboard",
    //     },
    //     {
    //       key: "/water-sector",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Water Utility performance",
    //     },
    //     {
    //       key: "/monitoring",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Monitoring & Evaluation",
    //     },
    //   ],
    // },
    // {
    //   key: "3",
    //   label: "PROJECT DEVELOPMENT",
    //   children: [
    //     {
    //       key: "/project-development",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Project development",
    //     },
    //     {
    //       key: "/performance-tracking",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Performance tracking",
    //     },
    //     {
    //       key: "/outcome-verification",
    //       icon: (
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='24'
    //           height='24'
    //           viewBox='0 0 24 24'
    //           fill='none'
    //         >
    //           <path
    //             opacity='0.35'
    //             d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
    //             fill='#7E93AE'
    //           />
    //           <path
    //             d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
    //             fill='#7E93AE'
    //           />
    //         </svg>
    //       ),
    //       label: "Outcome verification",
    //     },
    //   ],
    // },

    // {
    //   key: "/loan-perfection",
    //   label: <Link to='/loan-perfection'>LOAN PERFECTION</Link>,
    //   children: [],
    // },
    // {
    //   key: "/reports",
    //   label: <Link to='/reports'>REPORTS</Link>,
    //   children: [],
    // },
    // {
    //   key: "/support",
    //   label: <Link to='/support'>SUPPORT</Link>,
    //   children: [],
    // },
    {
      key: "7",
      label: "MY ACCOUNT ",
      children: [
        {
          key: "/account",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Account",
        },
      ],
    },
  ];

  useEffect(() => {}, [collapsed]);

  return (
    <>
      <div
        style={
          {
            // borderInlineEnd: '1px solid rgba(5, 5, 5, 0.06)',
          }
        }
        className='flex flex-col py-[1.56rem] h-[100vh] flex-shrink-0 w-[17.3125rem] bg-softblu'
      >
        <div className='flex items-end w-full '>
          <img src={image1} className='logo' alt='image2' />
        </div>

        <div className='mt-[2rem]'>
          <Menu
            onClick={handleNavigation}
            style={{
              width: "auto",
            }}
            defaultSelectedKeys={["/home"]}
            defaultOpenKeys={["3", "4", "5", "6", "7", "8", "9"]}
            openKeys={["3", "4", "5", "6", "7", "8", "9"]}
            mode='inline'
            items={items}
            theme='light'
            inlineCollapsed={collapsed}
          />
        </div>
      </div>
    </>
  );
}
