import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,
  mngtToolData: [],
};

export const fetchManagementTool = createAsyncThunk(
  "creditSlice/fetchManagementTool",
  async (data) => {
    const response = await axios.get(
      `${url}/usr/fetchManagementTool.action?mtProjId=${data?.projId}`
    ); 
    return response.data?.data?.result;
  }
);
export const deleteManagementTool = createAsyncThunk(
	"creditSlice/deleteManagementTool",
	async (data) => {
	  const response = await axios.get(
		`${url}/usr/deleteManagementTool.action?mtId=${data?.mtId}`
	  ); 
    console.log("response",response?.data)
	  return response.data;
	}
  );

 

export const mngtSlice = createSlice({
  name: "mngt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchManagementTool.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchManagementTool.fulfilled, (state, action) => {
        state.loading = false;
        state.mngtToolData = action.payload;
      })
      .addCase(fetchManagementTool.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteManagementTool.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteManagementTool.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteManagementTool.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default mngtSlice.reducer;
export const {} = mngtSlice.actions;
