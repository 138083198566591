import React from "react";
import svg1 from "../../../assets/svg/svg1.svg";

function RegistrationReview({ next, wspApplications }) {
  return (
    <>
      <div className="flex flex-col justify-center text-center items-center h-[40.25rem] ">
        <img src={svg1} className=" " alt="svg1" />
        {wspApplications?.projStatus === "DRAFT" ? (
          <>
            <div className="review_header_txt mt-[1.85rem]">
              Your account registration has been approved
            </div>
            <div className="review_desc_txt  mt-[.62rem]">
              You may proceed to the next section
            </div>

            <div className="mt-[3.25rem]">
              <button onClick={() => next()} className="cstm-btn">
                Next
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="review_header_txt mt-[1.72rem]">
              Your registration is under review
            </div>
            <div className="review_desc_txt flex flex-col mt-[.88rem]">
              Your registration has been submitted & will be reviewed by our
              team.
              <span>
                You will be notified if any extra information is needed.
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default RegistrationReview;
