import { Divider } from 'antd'
import React, { useEffect } from 'react'
import svg12 from '../../assets/svg/svg12.svg'
import svg13 from '../../assets/svg/svg13.svg'
import svg14 from '../../assets/svg/svg14.svg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWspProject } from '../../features/wsp/wspSlice'
import { add18Days, add30Days, addTwoWeeks, dateForHumans } from '../../utils'

function Timelines() {
  const dispatch =  useDispatch()
  const { user } = useSelector((state) => state.auth);
  const { wspApplications } = useSelector((state) => state.wsp);

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

 

  useEffect(() => {
    fetchWspProjectData();
  }, [user]);

  console.log("wspApplications",wspApplications?.projCreatedDate)
  return (
    <div>
          <div className="offer-title">Project Timeline</div>
          <Divider className='mt-[10px]'/>

           

       
            <div className='grid grid-cols-12 timeline_header'>
            <div className='col-span-2  px-[1.25rem]'> Activity</div>
            <div className='col-span-6   px-[1.25rem]'>Status</div>
            <div className='col-span-2   px-[1.25rem]'>Timeline</div>
            <div className='col-span-2   px-[1.25rem]'>Completion date</div>
          </div>
           
        

          <div className='mt-[.75rem] timeline_person'>You</div>
            <div className='mt-[.25rem] h-full  rounded-[.375rem] border border-[#78CC9B] bg-[#DCFFEA]'>
            <div className='grid grid-cols-12 timeline-content items-center '>
            <div className='col-span-2 text-[#FFF] timeline-col1 bg-[#1DAA58] w-full h-full 
              py-[1.5rem]  px-[1.25rem] flex items-center gap-x-1'>
                <img src={svg12} alt="svg12"/>
                Application</div>
            <div className='col-span-6   px-[1.25rem] text-[#21AC5B]'>Submitted successfully.</div>
            <div className='col-span-2   px-[1.25rem]'> </div>
            <div className='col-span-2   px-[1.25rem] text-[#21AC5B]'>{dateForHumans(wspApplications?.projCreatedDate)}</div>
          </div>
            </div>
            


            <div className='mt-[2.5rem] timeline_person !text-[#222]'>Technical advisor</div>
            <div className='mt-[.25rem] h-full  rounded-[.375rem] border-2 border-[#3346E7] bg-[#FFF]'>
            <div className='grid grid-cols-12 timeline-content items-center '>
            <div className='col-span-2 text-[#FFF] timeline-col1 bg-[#3346E7] w-full  h-full 
             py-[1.5rem]   px-[1.25rem] flex items-center gap-x-1'>
                <img src={svg14} alt="svg14"/>
                Application</div>
            <div className='col-span-6    px-[1.25rem] text-[#3346E7]'>Technical team is reviewing your application</div>
            <div className='col-span-2    px-[1.25rem] text-[#3346E7]'>2 weeks</div>
            <div className='col-span-2    px-[1.25rem] text-[#3346E7]'>{dateForHumans(addTwoWeeks(wspApplications?.projCreatedDate))}</div>
          </div>
            </div>


            <div className='mt-[2.5rem] timeline_person !text-[#7E93AE]'>Technical advisor</div>
            <div className='mt-[.25rem]   rounded-[.375rem]  border border-dotted border-[#7E93AE] bg-[#FFF]'>
            <div className='grid grid-cols-12 timeline-content items-center '>
            <div className='col-span-2 text-[#FFF] timeline-col1 bg-[#7E93AE] w-full h-full 
           py-[1.5rem]  px-[1.25rem] flex items-center gap-x-1'>
                <img src={svg13} alt="svg13"/>
                Preparation</div>
            <div className='col-span-6    px-[1.25rem] text-[#7E93AE] font-[400] text-[1rem] w-[48%]'>Technical team will prepare the project for submission to financial institution</div>
            <div className='col-span-2    px-[1.25rem] text-[#7E93AE] font-[400] text-[1rem]'>4 days</div>
            <div className='col-span-2    px-[1.25rem] text-[#7E93AE] font-[400] text-[1rem]'>{dateForHumans(add18Days(wspApplications?.projCreatedDate))}</div>
          </div>
            </div>


            <div className='mt-[2.5rem] timeline_person !text-[#7E93AE]'>Financial institution</div>
            <div className='mt-[.25rem] h-full rounded-[.375rem]  border border-dotted border-[#7E93AE] bg-[#FFF]'>
            <div className='grid grid-cols-12 timeline-content items-center '>
            <div className='col-span-2 text-[#FFF] timeline-col1 bg-[#7E93AE] w-full h-full 
           py-[1.5rem]  px-[1.25rem] flex items-center gap-x-1'>
                <img src={svg13} alt="svg13"/>
                Loan Offer</div>
            <div className='col-span-6   px-[1.25rem] text-[#7E93AE] font-[400] text-[1rem] w-[48%]'>After analysis, financial institutions will 
            make an offer.</div>
            <div className='col-span-2   px-[1.25rem] text-[#7E93AE] font-[400] text-[1rem]'>2 weeks</div>
            <div className='col-span-2   px-[1.25rem] text-[#7E93AE] font-[400] text-[1rem]'>{dateForHumans(add30Days(wspApplications?.projCreatedDate))}</div>
          </div>
            </div>
    </div>
  )
}

export default Timelines