import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatPath, removeCommas } from "../../../utils";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import ConnectionType from "./ConnectionType";
import GridReference from "./GridReference";
import { save, saveFile } from "../../../features/save/saveSlice";
import toast from "react-hot-toast";
import numeral from "numeral";
import { fetchWspProject } from "../../../features/wsp/wspSlice";

function CustomerInformation({ next, prev, wspApplications }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState(wspApplications);

  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  async function handleOpenFIle1() {
    document.getElementById("file1").click();
  }
  async function handleOpenFIle2() {
    document.getElementById("file2").click();
  }
  const [fileUrlAccnt, setfileUrlAccnt] = useState(
    wspApplications?.projIndActiveConnMbrFile ?? ""
  );
  async function handleCancelAccnt() {
    await setfileUrlAccnt();
  }
  async function handleUploadAccnt(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlAccnt(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlContract, setfileUrlContract] = useState(
    wspApplications?.projIndActiveConnCustFile ?? ""
  );
  async function handleCancelFileContract() {
    await setfileUrlContract();
  }
  async function handleUploadContract(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlContract(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projIndividualActiveConn: removeCommas(
          formData?.projIndividualActiveConn
        ),
        projIndividualClosedConn: removeCommas(
          formData?.projIndividualClosedConn
        ),
        projIndActiveConnMbrFile: fileUrlAccnt,
        projIndActiveConnCustFile: fileUrlContract,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleNext();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    const numericValue = Number(cleanedValue).toString();
    const formattedValue = numeral(numericValue).format("0,0");

    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const [subStep, setSubStep] = useState(0);

  // const handleNext = () => {
  //   if (subStep === 0) {
  //     setSubStep(subStep + 1);
  //   } else {
  //     next();
  //   }
  // };
  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

  const handleNext = async () => {
    await setSubStep(subStep + 1);
    await fetchWspProjectData();
  };

  const handlePrev = async () => {
    await fetchWspProjectData();
    if (subStep === 0) {
      prev();
    } else {
      setSubStep(subStep - 1);
    }
  };

  const onChangeOther = (value, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <>
      <div>
        {subStep === 0 && (
          <div className="flex flex-col  h-full py-[2.25rem] px-[3.88rem] justify-start items-start">
            <div className="profile_header">c) Customer Information</div>

            <div className="profile_sub mt-[.94rem]">
              Please fill in the following details
            </div>

            <div>
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                style={{
                  maxWidth: "100%",
                }}
                className="w-[600px] mt-[1.13rem]"
                form={form}
              >
                <div className="flex flex-col">
                  <Form.Item
                    extra={"Enter individual active connections"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please add individual active connections",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projIndividualActiveConn"
                      value={formData?.projIndividualActiveConn}
                      placeholder="Individual active connections"
                      min={0} 
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) => onChangeOther(value, "projIndividualActiveConn")}
                      className="input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Enter individual closed connections"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please add individual closed connections",
                      },
                    ]}
                  >
                    <InputNumber 
                      name="projIndividualClosedConn"
                      value={formData?.projIndividualClosedConn}
                      placeholder="Individual closed connections"
                      min={0} 
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) => onChangeOther(value, "projIndividualClosedConn")}
                      className="input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Upload PDF of list of members or accounts.pdf"}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlAccnt"}
                  >
                    {fileUrlAccnt ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlAccnt)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelAccnt}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle1}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file1"
                            name="file"
                            onChange={(e) => handleUploadAccnt(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            PDF of list of members or accounts
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item
                    extra={"Upload PDF of sample customer contract .pdf"}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlContract"}
                  >
                    {fileUrlContract ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlContract)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileContract}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle2}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file2"
                            name="file"
                            onChange={(e) => handleUploadContract(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            PDF of sample customer contract
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                    <button type="submit" className="cstm-btn">
                      {saving ? <Spin /> : "Next"}
                    </button>
                    <button onClick={prev} className="border-btn mt-[.75rem]">
                      Previous
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
        {subStep === 1 && (
          <ConnectionType next={handleNext} prev={handlePrev} nextStep={next} />
        )}
        {subStep === 2 && (
          <GridReference next={handleNext} prev={handlePrev} nextStep={next} />
        )}
      </div>
    </>
  );
}

export default CustomerInformation;
