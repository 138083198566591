import React from "react";
import waterImg from "../../assets/img/bgImg.png";
import { Steps } from "antd";

export default function AuthSideImage({ hideStepper, hideText, current }) {
  return (
    <>
      <div className="h-full w-full bgImg">
        {hideText ? null : (
          <div className="flex justify-center flex-col text-left px-[48px] w-full">
            <h3 className="signup-info mt-20 ">Sign up and get financed.</h3>
            <p className="signup-info-text mt-5">
              Signing up as a water service provider is fast and easy.
            </p>
            <p className="signup-info-text mt-1">
              Be sure to provide accurate details, to hasten the eligibility
              process.
            </p>
          </div>
        )}

        {hideStepper ? null : (
          <>
            <Steps
              className="mt-[56px] px-[48px] relative"
              direction="vertical"
              current={current}
              items={[
                {
                  title: "Basic Details",
                },
                {
                  title: "Business Details",
                },
                {
                  title: "Management Details",
                },
                {
                  title: "Project Description",
                },
                {
                  title: "Password Setup",
                },
              ]}
            />
          </>
        )}

        <div className="">
          <img
            className={
              hideStepper
                ? "absolute bottom-0 h-[400px] w-full object-fill"
                : "h-[100vh] w-full object-cover bg-[#226cbf]"
            }
            src={waterImg}
            alt="water-background"
          />
        </div>
      </div>
    </>
  );
}
