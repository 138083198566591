import { useEffect } from 'react';
import { useRef } from 'react';

export default function ScrollToTop() {
	const elementRef = useRef();

	useEffect(() => {
        elementRef.current.scrollIntoView()
    });

	return <div ref={elementRef} />;
}
