import React, { useState } from 'react'
import PageHeader from '../../../components/PageHeader'
import { Avatar, Button, Descriptions } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { formatImgPath } from '../../../utils';
import AddUserModal from './components/AddUserModal';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteUser, fetchCurrentUser } from '../../../features/auth/authSlice';
import { fetchAllUser } from '../../../features/auth/authSlice';



const MyAccount = ({ setIsEdit }) => {
    const { allUsers } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [open, setOpen] = useState(false);

    const deleteNewUser = (userId) => {
        dispatch(deleteUser(userId))
        dispatch(fetchAllUser({ accId: user.usrAccId }))
    }


    const items = [
        {
            key: '1',
            label: 'Company',
            children: user.accTradeName ?? 'N/A',
        },
        {
            key: '2',
            label: 'Business Reg No',
            children: user.accRegNo ?? 'N/A',
        },
        {
            key: '3',
            label: 'Location',
            children: user.accLocation ?? 'N/A',
        },
        {
            key: '4',
            label: 'Company email',
            children: user.accEmail ?? 'N/A',
        }
    ];

    const userData = [
        {
            key: '1',
            label: 'Name',
            children: `${user.usrFirstName ?? 'N/A'} ${user.usrLastName ?? 'N/A'}`,
        },
        {
            key: '2',
            label: 'Email',
            children: user.usrEmail ?? 'N/A',
        },
        {
            key: '3',
            label: 'Phone',
            children: user.usrMobileNumber ?? 'N/A',
        }
    ];


    const OtherUserElement = allUsers.map(item => {
        const otherUser = [
            {
                key: '1',
                // label: 'Name',
                children: `${item.usrFirstName ?? 'N/A'} ${item.usrLastName ?? 'N/A'}`,
            },
            {
                key: '2',
                // label: 'Email',
                children: item.usrEmail ?? 'N/A',
            },
            {
                key: '3',
                // label: 'Phone',
                children: item.usrMobileNumber ?? 'N/A',
            }
        ];
        return (
            <div className='mt-5'>
                <Descriptions
                    column={1}
                    colon={false}
                    extra={<Button
                        danger
                        icon={<DeleteOutlined />}

                        onClick={() => deleteNewUser(item.usrId)}
                    />}
                    // title='User Details'
                    items={otherUser}
                />
            </div>
        )
    })


    return (
        <div className='flex flex-col h-screen  w-[100%]'>
            <PageHeader header={'My profile'} />
            <div className='relative flex flex-row bg-[#EDF3FB] justify-end items-center h-[15%] w-full mt-10'>
                <button
                    onClick={() => setIsEdit(true)}
                    className='cstm-btn h-[40px] text-[0.875rem] mr-10 mt-10 mb-10 '>Edit profile</button>
                <div className='absolute left-[5%] rounded-full bg-white top-[50%]'>
                    <Avatar
                        size={150}
                        src={formatImgPath(user.accIcon == null ? "./myimages/OTP_IMAGES/FUND_MY_WATER/avatar.png" : user.accIcon)}
                    />
                </div>
            </div>

            <div className='flex flex-row mt-36'>
                <div className='flex flex-col w-[50%]'>
                    <Descriptions
                        column={1}
                        colon={false}
                        title='Company Information'
                        items={items}
                    />
                    <div>
                        <Descriptions
                            column={1}
                            colon={false}
                            title='User Details'
                            items={userData}
                        />
                    </div>
                </div>
                <div className='font-[700]'></div>
                <div className='flex flex-col bg-gren w-[50%] '>
                    <div className='flex flex-row justify-between items-center'>
                        <div className='font-[600] text-blk mt-10'>Sub users</div>
                        <button
                            onClick={() => setOpen(true)}
                            className='cstm-btn h-[40px]  text-[0.875rem] mr-10 mt-10 '>Add user</button>
                    </div>
                    {OtherUserElement.slice(1)}
                </div>
            </div>
            <AddUserModal setOpen={setOpen} open={open} />
        </div >
    )
}
export default MyAccount