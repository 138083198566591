import React from 'react';
import { Breadcrumb } from 'antd';

export default function BreadCrumb({ breadList }) {
	return (
		<>
			<div className=''>
				<Breadcrumb
					separator={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='6'
							height='9'
							viewBox='0 0 6 9'
							fill='none'>
							<path
								d='M0 7.9425L3.435 4.5L0 1.0575L1.0575 0L5.5575 4.5L1.0575 9L0 7.9425Z'
								fill='#545D69'
							/>
						</svg>
					}
					items={breadList}
				/>
			</div>
		</>
	);
}
